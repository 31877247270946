import React from "react";
import { Box, Typography } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { ErrorMessage } from "formik";
import ErrorMsg from './ErrorMsg';


export const Telephone = (props) => {
  const { label, name, ...rest } = props;

  const [phone, setPhone] = React.useState("+234");

  const handleChange = (newPhone) => {
    setPhone(newPhone);
    // matchIsValidTel(newPhone) // boolean
  };

  return (
    <Box sx={{ marginBottom: "2rem",fontFamily:"Poppins" }}>
      <Typography variant="label" component="label" htmlFor={name}>
      <Typography component="span" sx={{color:"red"}}>*</Typography>
        {label}
      </Typography>
      <br />
      <MuiTelInput
        name={name}
        id={name}
        value={phone}
        onChange={handleChange}
        {...rest}
        sx={{
          backgroundColor: "rgba(0,0,0,0.04)",
          border: "none",
          width: "100%",
          height: "50px",
          margin: "1rem 0 0 0",
       "& .MuiOutlinedInput-input":{ fontSize:"1.2rem",},
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              border: "none",  
             
            },
          },
        }}
      />
      <ErrorMessage name={name} component={ErrorMsg}   />

    </Box>
  );
};
