import * as React from "react";
import {
  AppBar,
  Box,
  useScrollTrigger,
  Slide,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../assets/images/dukka-logo.png";


const drawerWidth = "90%";
const navData = [
  { id: 1, link: "Home", path: "/", href:"https://dukka.com/" },
  { id: 2, link: "About", path: "/about", href:"https://dukka.com/about/" },
  { id: 3, link: "FAQ", path: "/faq", href:"https://dukka.com/faq/" },
  { id: 4, link: "Contact", path: "/contact", href:"https://dukka.com/contact/" },
  { id: 5, link: "Career", path: "/career", href:"https://dukka.com/career/" },
  { id: 6, link: "Blog", path: "/blog", href:"https://dukka.com/blog/" },
  { id: 7, link: "Launchpad", path: "/launchpad", href:"https://launchpad.dukka.com/" },
  
];

function HideOnScroll(props) {
  const { children, window } = props;
  
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem",
        }}
      >
     

        <IconButton sx={{backgroundColor:"#a5a5a5", height:"50px",width:"50px", borderRadius:"50%", display:"flex",justifyContent:"center", alignItems:"center"}} >
          <CloseIcon
            sx={{ color: "#fff", fontSize: "1.5rem", }}
          />
        </IconButton>
      </Box>
      {/* <Divider /> */}
      <List sx={{ 
        padding:"1rem",
        textAlign: "center" }}>
        {navData.map((nav) => (
          <ListItem
            key={nav.id}
            disablePadding
            sx={{
            
              display: "block",
              boxShadow:"0 13px 25px -17px rgb(45 25 82 / 18%)"

            }}
          >
           
              <ListItemButton component="a" href={nav.href} sx={{ textAlign: "center",  textTransform:"capitalize" }}>
                <ListItemText
                  primary={nav.link}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "1.1rem",
                      color: " #151218;",
                      textAlign: "left",
                    lineHeight:"40px"
                    },
                  }}
                />
              </ListItemButton>
          
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box  >
      <HideOnScroll {...props}>
        <AppBar
          component="nav"
          sx={{
            backgroundColor: "#fff",
            boxShadow: "none",
            color: "#151218",

          }}
        >
          <Toolbar sx={{width:{xs:"90%", sm:"85%", lg:"82%"}, marginInline:"auto",
            padding: { sm: " 3rem 0 ", xs: "1rem 0", lg: "2.5rem 0" },
        
        }}>
            <Box  component="a"
                href="https://dukka.com/" sx={{ display: { xs: "none", md: "initial" }, flexBasis:"25%"}}>
              <Box
                src={Logo}
                alt="logo"
                component="img"
                sx={{ maxHeight: { xs: "40px", sm: "35px" } }}
              />
            </Box>

            <Box
              sx={{
                flexBasis:"75%",
                display: { xs: "none", md: "flex" },
                flexDirection: "row",
               
              }}
            >
              {navData.map((nav) => (
                // desktop navlinks
                <Box  key={nav.id} >
                  <Button
                  component="a" href={nav.href}
                    sx={{
                      color: "#151218",
                      marginRight: {  md: "0.1rem", xs: "0" },
                      fontSize:{md:"1.1rem", lg:"1.2rem"},
                      textTransform:"capitalize",
                      textDecoration:"none",cursor:"pointer",
                      fontWeight:"400"

                    }}
                  >
                    {nav.link}
                  </Button>
                 </Box>
              ))}
            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "initial" },
                display: { xs: "flex", md: "block" },
                justifyContent: { xs: "space-between" },
                alignItems: { xs: "center" },
              }}
            >
              <Box
              component="a"
              href="https://dukka.com/" 
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                }}
              >
                <Box
                  src={Logo}
                  alt="logo"
                  component="img"
                  sx={{ maxHeight: { xs: "40px", sm: "35px" } }}
                />
              </Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ display: { md: "none" } }}
              >
                <MenuIcon sx={{ fontSize: "2.5rem", fontWeight: "light" }} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
