import { Box, Button } from "@mui/material";
export const CustomButton = ({
  text = "proceed",
  disabled,
  onClick,
  color,
  component,
  ...rest
}) => {
  return (
    <Box component="div" textAlign="center">
      <Button
        color={color || "secondary"}
        component={component}
        variant="contained"
        type="submit"
        size="large"
        sx={{
          width: "50%",
          mx: "auto",
          textTransform: "capitalize",
          color: "#fff",
          fontSize: "12px",
          padding: 1.5,
          mt: 2,
        }}
        disabled={disabled}
        onClick={onClick}
        {...rest}
      >
        {text}
      </Button>
    </Box>
  );
};
