export const LandingPageStyle = (bgColor, hover) => {
  return {
    WrapperBox: {
      width: { xs: "90%", lg: "85%" },
      marginInline: "auto",
      padding: "3rem 0",
    },
    HeroTitle: {
      fontWeight: { xs: "600", sm: "700" },
      fontSize: { xs: "2.5rem", sm: "2.7rem", md: "3rem" },
    },
    HeroSubtitle: {
      fontSize: { xs: "1.3rem", md: "1.5rem" },
      margin: "1.5rem 0",
      width: { xs: "100%", sm: "320px", md: "400px", lg: "550px" },
    },
    Button: {
      width: "200px",
      height: "58px",
      backgroundColor: bgColor,
      color: "#fff",
      fontSize: "0.9rem",
      borderRadius: "7px",
      "&:hover": {
        backgroundColor: hover,
      },
    },
    HeroImgBox: {
      width: { xs: "100%", lg: "450px" },
      height: "auto",
      objectFit: "contain",
    },
    LoanCriteriaBox: {
      width: { xs: "90%", lg: "85%" },
      marginInline: "auto",
      padding: { xs: "3rem 0", md: "3rem 0 5rem 0" },
    },
    LoanCriteriaTitle: {
      fontWeight: { xs: "400", sm: "500" },
      fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
      marginBottom: { xs: "1rem", sm: "0" },
    },
    GreenCheckListItem: {
      "& .MuiTypography-root": {
        fontSize: { xs: "1.1rem", sm: "1rem", md: "1.3rem" },
        marginBottom: { xs: "0.7rem", sm: "0", md: "0.5rem" },
        paddingRight: { sm: "1rem", md: "0.5rem", lg: "0" },
      },
    },
    ListItem: {
      "& .MuiTypography-root": {
        fontSize: { xs: "1.1rem", md: "1.3rem" },
        marginBottom: { xs: "0.7rem", sm: "0", md: "0.5rem" },
        paddingRight: { sm: "3rem" },
      },
    },
    LoanCriteriaImg: {
      width: { xs: "100%", lg: "450px" },
      height: "auto",
      objectFit: "contain",
    },
    LoanStepTypography1: {
      textAlign: "center",
      fontWeight: "400",
      fontSize: { xs: "2rem", md: "2.5rem" },
    },
    LoanStepBox1: {
      padding: { xs: "2.5rem 0" },
      backgroundColor: "#F5F6FA",
    },
    LoanStepBox2: {
      width: { xs: "90%", sm: "80%", lg: "70%" },
      marginInline: "auto",
    },
    LoanStepBox3: {
      display: "flex",
      flexDirection: { xs: "column", sm: "row" },
      justifyContent: { xs: "center", sm: "space-between" },
      alignItems: "center",
      margin: "1rem 0 3rem 0",
    },
    LoanStepBox4: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textDecoration: "none",
    },
    LoanBenefitBox1: {
      padding: {
        xs: "3rem 0 3rem 0",
        sm: "1rem 0 3rem 0",
        md: "3rem 0 4rem 0",
      },
    },
    LoanBenefitBox2: {
      width: "90%",
      marginInline: "auto",
    },
    LoanBenefitTypography1: {
      textAlign: "center",
      fontWeight: "400",
      fontSize: { xs: "2rem", md: "2.5rem" },
    },
    CarouselBox1: {
      width: { xs: "100%", md: "80%" },
      marginInline: "auto",
    },
    StepBox1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    StepBox2: {
      backgroundColor: "#ECF0FF",
      height: "100px",
      width: "100px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    StepTypography1: {
      fontSize: "2rem",
      fontWeight: "400",
    },
  };
};
