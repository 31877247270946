import { Footer } from "components/Footer/Footer";
import { LoanGuarantorForm } from "components/LoanGuarantorForm/LoanGuarantorForm";
import { Navbar } from "components/Navbar/Navbar";
import { HomePage } from "pages/HomePage/HomePage";
import { Routes, Route } from "react-router-dom";
import { PersonalDetails } from "components/LoanRequest/PersonalDetails/PersonalDetails";
import { LoanDetails } from "components/LoanRequest/LoanDetails/LoanDetails";
import { BusinessDetails } from "components/LoanRequest/BusinessDetails/BusinessDetails";
import { BankDetails } from "components/LoanRequest/BankDetails/BankDetails";
import { GuarantorsDetails } from "components/LoanRequest/GuarantorsDetails/GuarantorsDetails";
import { TermsandConditions } from "components/LoanRequest/TermsandConditions/TermsandConditions";
import { Feedback } from "components/LoanRequest/Feedback/Feedback";
import { LinkBankAccount } from "components/LoanRequest/LinkBankAccount/LinkBankAccount";
import { Error } from "components/ErrorPage/Error";
import { ApplicantForm } from "pages/ApplicantForm/ApplicantForm";

export const AppRoute = () => {
  return (
    <>
      <Navbar />
      <main>
        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route path="guarantor-approval" element={<LoanGuarantorForm />} />
          <Route path="terms-and-conditions" element={<TermsandConditions />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="link-bank-account" element={<LinkBankAccount />} />
          <Route path="applicant-form" element={<ApplicantForm />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};
