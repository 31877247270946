import { Input } from "../Input/Input"
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup';
import { Select } from "../Select/Select";
import { DateInput } from '../DatePicker/DatePicker';

export const FormikControl = ({control, ...rest}) => {   
   switch (control) {
     case "input":
       return <Input {...rest} />;
     case "radio":
       return <RadioButtonGroup {...rest} />;
     case "select":
       return <Select {...rest} />;
     case "date":
       return <DateInput {...rest} />;
     default:
       return <Input {...rest} />;
   }
  
}