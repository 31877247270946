export const Style = {
  Box: {
    marginTop: { xs: "1rem", sm: "3rem", md: "4rem" },
    width: { xs: "90%", lg: "80%" },
    marginInline: "auto",
    padding: "3rem 0",
  },

  TitleTypography: {
    fontSize: { xs: "1.7rem", md: "3rem" },
    fontWeight: "600",
    textAlign: "center",
    margin: { xs: "0 0 2rem 0", md: "0 0 5rem 0" },
  },
  SubtitleBox: {
    marginBottom: { xs: "2rem" },
    fontFamily: "Poppins",
  },
  SubTitleTypography: {
    fontSize: "1.2rem",
    fontWeight: "400",
    marginBottom: "0.3rem",
  },
  GreyColor: {
    color: "#9A9A9A",
  },
  InputStyle: {
    backgroundColor: "rgba(0,0,0,0.04)",
    border: "none",
    width: { xs: "100%" },
    height: "50px",
    margin: "1rem 0 0 0",
    fontSize: "1.2rem",
    paddingLeft: "1rem",
  },
  amountRequested: {
    backgroundColor: "rgba(0,0,0,0.04)",
    border: "none",
    width: { xs: "100%" },
    height: "50px",
    // margin: "1rem 0 0 0",
    // fontSize: "1.2rem",
    // paddingLeft: "1rem",
  },
  ConsentTypography: {
    color: "#8E8E93",
    marginBottom: "3rem",
    marginTop: "1rem",
    display: "block",
    lineHeight: "2",
  },
  Submit: {
    "&.Mui-disabled": {
      background: "#eaeaea",
      color: "#c0c0c0",
    },
    backgroundColor: "#231F20",
    display: "block",
    color: "#fff",
    width: "50%",
    height: "50px",
    marginInline: "auto",
    "&:hover": {
      backgroundColor: "#231F20",
      color: "#fff",
    },
  },
  DateSignatureContainer: {
    "& .MuiButtonBase-root-MuiButton-root": {
      marginTop: "2rem",
    },
    marginBottom: "3rem",
  },
};
