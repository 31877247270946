import { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { PassportUpload } from "components/LoanRequest/GuarantorsDetails/PassportUpload";
import { FormikControl } from "components/Forms/FormikControl/FormikControl";
import { TextError } from "components/Forms/TextError/TextError";
import { PersistFormikValues } from "formik-persist-values";
import { employmentOptions } from "./GuarantorsDetailsSchema";

export const GuarantorsDetails = ({ setGuarantorValues }) => {
  const formik = useFormikContext();

  // useEffect(() => {
  //   // Call the callback function whenever form values change
  //   setGuarantorValues(formik.values.guarantorDetails);
  // }, [formik.values.guarantorDetails, setGuarantorValues]);
  return (
    <Box>
      <Box sx={{ margin: "1rem 0", fontFamily: "Poppins", fontSize: "0.7rem" }}>
        <Box
          component="div"
          sx={{
            // color: "#000",
            // fontSize: "1.2rem",
            textAlign: "left",
            // marginLeft: {
            //   md: "2rem",
            // },
          }}
        >
          Kindly note that:
        </Box>
        <Box
          component="ul"
          sx={{
            listStyleType: "none",
            textAlign: "left",

            // marginLeft: {
            //   md: "2rem",
            // },
          }}
        >
          <Box component="li">
            (i) Family members e.g spouse, siblings, uncles, aunts etc, do not
            qualify as guarantors
          </Box>
          <Box component="li">
            {" "}
            (ii) Guarantor must be gainfully employed with a reputable
            organisation
          </Box>
        </Box>
      </Box>

      <Box>
        <Grid container spacing={{ md: 12 }} sx={{ pb: 3 }}>
          <Grid item md={12} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="guarantorDetails.guarantor_full_name">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="input"
                  label="Guarantor's Full  Name"
                  name="guarantorDetails.guarantor_full_name"
                  pattern="[A-Za-z]"
                  field={field}
                  meta={meta}
                  value={formik.values.guarantorDetails.guarantor_full_name.replace(
                    /[^a-z]\s/gi,
                    ""
                  )}
                  onChange={formik.handleChange(
                    "guarantorDetails.guarantor_full_name"
                  )}
                />
              )}
            </Field>
          </Grid>
        </Grid>
        <Grid container spacing={{ md: 6 }} sx={{ pb: 3 }}>
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="guarantorDetails.guarantor_employment_status">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="select"
                  label="His/Her employment status"
                  name="guarantorDetails.guarantor_employment_status"
                  options={employmentOptions}
                  value={
                    formik.values.guarantorDetails.guarantor_employment_status
                  }
                  onChange={formik.handleChange(
                    "guarantorDetails.guarantor_employment_status"
                  )}
                  field={field}
                  meta={meta}
                />
              )}
            </Field>
          </Grid>
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Box
              component={PassportUpload}
              required="*"
              label="His/Her passport photograph"
              name="guarantorDetails.guarantor_passport"
              error="This field is required"
              fileTitle="Max size 200kb"
              value={formik.values.guarantor_passport}
              onChange={(file) => {
                formik.setFieldValue(
                  "guarantorDetails.guarantor_passport",
                  file
                );
              }}
            />
            <ErrorMessage
              name="guarantorDetails.guarantor_passport"
              component={TextError}
            />
          </Grid>
        </Grid>
        {formik.values.guarantorDetails.guarantor_employment_status ===
          "Employed" && (
          <Grid container spacing={{ md: 6 }} sx={{ pb: 3 }}>
            <Grid item md={12} xs={12}>
              <Field name="guarantorDetails.guarantor_employer">
                {({ field, meta }) => (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Employer"
                    name="guarantorDetails.guarantor_employer"
                    field={field}
                    meta={meta}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={{ md: 6 }} sx={{ pb: 3 }}>
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="guarantorDetails.guarantor_phone_number">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="input"
                  label="Guarantor's phone number"
                  name="guarantorDetails.guarantor_phone_number"
                  type="tel"
                  maxLength={11}
                  field={field}
                  meta={meta}
                  value={formik.values.guarantorDetails.guarantor_phone_number.replace(
                    /[^0-9]/gi,
                    ""
                  )}
                  onChange={formik.handleChange(
                    "guarantorDetails.guarantor_phone_number"
                  )}
                />
              )}
            </Field>
          </Grid>
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="guarantorDetails.guarantor_address">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="input"
                  label="Guarantor's Address"
                  name="guarantorDetails.guarantor_address"
                  placeholder="No.,Street,city,state"
                  field={field}
                  meta={meta}
                />
              )}
            </Field>
          </Grid>
        </Grid>
        <Grid container spacing={{ md: 6 }} sx={{ pb: 3 }}>
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="guarantorDetails.guarantor_email_address">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="input"
                  label="His/Her email address"
                  name="guarantorDetails.guarantor_email_address"
                  field={field}
                  meta={meta}
                  value={formik.values.guarantorDetails.guarantor_email_address}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "guarantorDetails.guarantor_email_address",
                      e.target.value.trim(e)
                    )
                  }
                />
              )}
            </Field>
          </Grid>
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="guarantorDetails.referral_code">
              {({ field, meta }) => (
                <FormikControl
                  control="input"
                  label="Referral code"
                  name="guarantorDetails.referral_code"
                  field={field}
                  meta={meta}
                />
              )}
            </Field>
          </Grid>
        </Grid>

        <PersistFormikValues
          name="guarantor-details"
          ignoreValues="guarantor_passport"
          storage="sessionStorage"
        />
      </Box>
    </Box>
  );
};
