import { TextError } from "../TextError/TextError";
import { ErrorMessage } from "formik";
import { FormControl, TextField, FormLabel, Typography } from "@mui/material";

export const Input = (props) => {
  const {
    label,
    name,
    type = "text",
    error,
    meta,
    field,
    required,
    helperText,
    maxLength,
    ...rest
  } = props;

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        "& .css-13nvzlm-MuiFormHelperText-root": {
          fontSize: "13px",
          ml: 0,
        },
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          color: "black",
          fontWeight: 500,
          paddingBottom: "10px",
        }}
      >
        {label}
        <Typography component="span" sx={{ color: "red", fontSize: "1rem" }}>
          {required}
        </Typography>
      </FormLabel>
      <TextField
        id={name}
        type={type}
        name={name}
        helperText={helperText}
        // value={field.value}
        // onChange={field.onChange}
        {...field}
        {...rest}
        inputProps={{
          autoComplete: "off",
          maxLength: maxLength,
        }}
      />
      <ErrorMessage name={name} component={TextError} />
    </FormControl>
  );
};
