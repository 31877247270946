import { Box } from "@mui/system";
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CImg1 from "assets/images/Group 20.png";
import CImg2 from "assets/images/Group 22.png";
import CImg3 from "assets/images/Group 23.png";
import CImg4 from "assets/images/Group 24.png";
import CImg5 from "assets/images/Group 25.png";
import { LandingPageStyle } from "../LandingPageStyle";

const carouselImages = [CImg1, CImg2, CImg3, CImg4, CImg5];

export class PauseOnHover extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      pauseOnHover: true,
      arrows: false,
    };
    return (
      <Box sx={LandingPageStyle().CarouselBox1}>
        <Slider {...settings}>
          {carouselImages.map((carouselImage, id) => (
            <Box sx={{ padding: { xs: "1rem 0", sm: "3rem 0" } }} key={id}>
              <Box
                component="img"
                src={carouselImage}
                alt="Carousel"
                sx={{ width: "100%" }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    );
  }
}
