import {  useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { TextError } from "components/Forms/TextError/TextError";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import {

  sexOptions,
  idOptions,
} from "./PersonalDetailsSchema";
import { FormikControl } from "../../Forms/FormikControl/FormikControl";
import { UploadID } from "./UploadID";
import { PersistFormikValues } from "formik-persist-values";
import { FormField } from "components/LoanGuarantorForm/formComponent/FormField";

export const PersonalDetails = ({ setPersonalValues }) => {



  const formik = useFormikContext();
  // console.log("personal", formik.values.personalDetails);

  // useEffect(() => {
  //   if (formik.values.personalDetails)
  //     // Call the callback function whenever form values change
  //     setPersonalValues(formik.values.personalDetails);
  // }, [formik.values.personalDetails, setPersonalValues]);

  return (
    <Box>
      <Grid container spacing={{ md: 12 }}>
        <Grid item md={12} xs={12} sm={12}>
          <Field name="personalDetails.full_name">
            {({ field, meta }) => (
              <FormikControl
                control="input"
                required="*"
                type="text"
                label="Full name"
                name="personalDetails.full_name"
                meta={meta}
                field={field}
                value={formik.values.personalDetails?.full_name.replace(
                  /[^a-z]\s/gi,
                  ""
                )}
                onChange={formik.handleChange("personalDetails.full_name")}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={{ md: 2 }}>
        <Grid item md={6} xs={12} sm={12}>
          <Box sx={{ mb: "20px" }}>
            <Box
              component={FormField}
              control="file"
              name="personalDetails.applicant_passport"
              fileTitle="Upload a selfie. Max size 200kb"
              type="file"
              value={formik.values.personalDetails.applicant_passport}
              onChange={(file) => {
                formik.setFieldValue(
                  "personalDetails.applicant_passport",
                  file
                );
              }}
            />
            <ErrorMessage
              name="personalDetails.applicant_passport"
              component={TextError}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <Box>
            <Box
              component={UploadID}
              control="file"
              name="personalDetails.means_of_identification_image
                    "
              fileTitle="Upload a means of identification. Max size 200kb"
              type="file"
              value={
                formik.values.personalDetails.means_of_identification_image
              }
              onChange={(file) => {
                formik.setFieldValue(
                  "personalDetails.means_of_identification_image",
                  file
                );
              }}
            />
            <ErrorMessage
              name="personalDetails.means_of_identification_image"
              component={TextError}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 3, md: 6 }}>
        <Grid item md={4} xs={6} sm={6}>
          <Field name="personalDetails.date_of_birth">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                control="date"
                maxDate={new Date("2005-12-31")}
                label="Date of Birth"
                name="personalDetails.date_of_birth"
                type="date"
                value={formik.values.personalDetails.date_of_birth}
                onChange={(date) => {
                  formik.setFieldValue("personalDetails.date_of_birth", date);
                }}
                meta={meta}
                field={field}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={4} xs={6} sm={6}>
          <Field name="personalDetails.sex">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                control="select"
                label="Sex"
                name="personalDetails.sex"
                value={formik.values.personalDetails.sex}
                onChange={formik.handleChange("personalDetails.sex")}
                options={sexOptions}
                meta={meta}
                field={field}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={4} xs={12} sm={12}>
          <Field name="personalDetails.home_address">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                label="Home address"
                name="personalDetails.home_address"
                control="input"
                type="text"
                meta={meta}
                field={field}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={{ md: 6 }}>
        <Grid item md={4} xs={12} sm={12}>
          <Field name="personalDetails.means_of_identification">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                control="select"
                label="Means of identification"
                name="personalDetails.means_of_identification"
                options={idOptions}
                meta={meta}
                field={field}
                value={formik.values.personalDetails.means_of_identification}
                onChange={formik.handleChange(
                  "personalDetails.means_of_identification"
                )}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={4} xs={12} sm={12}>
          <Field name="personalDetails.email">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                control="input"
                label="Email address"
                name="personalDetails.email"
                type="email"
                meta={meta}
                field={field}
                value={formik.values.personalDetails.email}
                onChange={(e) =>
                  formik.setFieldValue(
                    "personalDetails.email",
                    e.target.value.trim(e)
                  )
                }
              />
            )}
          </Field>
        </Grid>
        <Grid item md={4} xs={12} sm={12}>
          <Field name="personalDetails.phone_number">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                control="input"
                label="Phone number"
                name="personalDetails.phone_number"
                type="tel"
                maxLength={11}
                meta={meta}
                field={field}
                value={formik.values.personalDetails.phone_number.replace(
                  /[^0-9]/gi,
                  ""
                )}
                onChange={formik.handleChange("personalDetails.phone_number")}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={{ md: 6 }} sx={{ pb: 3 }}>
        <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
          {(formik.values.personalDetails.means_of_identification ===
            "Passport" ||
            formik.values.personalDetails.means_of_identification ===
              "Driver's license" ||
            formik.values.personalDetails.means_of_identification ===
              "Voter's card" ||
            formik.values.personalDetails.means_of_identification ===
              "NIN") && (
            <Field name="personalDetails.means_of_identification_no">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="input"
                  label="Fill in your Means of Identification Number:"
                  name="personalDetails.means_of_identification_no"
                  meta={meta}
                  field={field}
                  value={
                    formik.values.personalDetails.means_of_identification_no
                  }
                  maxLength={
                    formik.values.personalDetails.means_of_identification ===
                    "Voter's card"
                      ? 19
                      : formik.values.personalDetails
                          .means_of_identification === "NIN"
                      ? 11
                      : formik.values.personalDetails
                          .means_of_identification === "Driver's license"
                      ? 12
                      : formik.values.personalDetails
                          .means_of_identification === "Passport"
                      ? 9
                      : null
                  }
                  onChange={formik.handleChange(
                    "personalDetails.means_of_identification_no"
                  )}
                />
              )}
            </Field>
          )}
        </Grid>
      </Grid>

      <PersistFormikValues
        name="personal-details"
        ignoreValues="date_of_birth"
        storage="sessionStorage"
      />
    </Box>
  );
};
