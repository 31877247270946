import { LoanRequestLayout } from "components/LoanRequest/LoanRequestLayout/LoanRequestLayout";
import { Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoanContext } from "context/LoanContext/LoanContext";
import { FormikControl } from "components/Forms/FormikControl/FormikControl";
import { initialValues, validationSchema } from "./BankDetailsSchema";
import { CustomButton } from "components/Button/Button";
import { PersistFormikValues } from "formik-persist-values";
import { bankNames } from "./BankDetailsSchema";

export const BankDetails = () => {
  const { setLoanRequest, loanRequest, setCompletedSteps } = useLoanContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onSubmit = (values) => {
    setLoanRequest({ ...loanRequest, ...values });
    setCompletedSteps((prev) => [...prev, pathname]);
    navigate("/guarantor's-details");
  };
  

  return (
    <LoanRequestLayout
      title="Bank Details"
      subtitle="Please enter your bank information to complete the loan application:"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <Grid container spacing={{ md: 6 }}>
                <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                  <Field name="account_number">
                    {({ field, meta }) => (
                      <FormikControl
                        required="*"
                        control="input"
                        label="Account number"
                        name="account_number"
                        maxLength={10}
                        value={formik.values.account_number.replace(
                          /[^0-9]/gi,
                          ""
                        )}
                        meta={meta}
                        field={field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} sm={12} sx={{ textAlign: "start" }}>
                  <Field name="bank_name">
                    {({ field, meta }) => (
                      <FormikControl
                        required="*"
                        control="select"
                        label="Bank"
                        name="bank_name"
                        type="text"
                        value={formik.values.bank_name}
                        onChange={formik.handleChange("bank_name")}
                        options={bankNames}
                        meta={meta}
                        field={field}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              {formik.values.bank_name === "others" && (
                <Grid container spacing={{ md: 6 }}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    sx={{ textAlign: "start" }}
                  >
                    <Field name="other_bank">
                      {({ field, meta }) => (
                        <FormikControl
                          required="*"
                          control="input"
                          label="Bank name"
                          name="other_bank"
                          type="text"
                          meta={meta}
                          field={field}
                          value={formik.values.other_bank.replace(/\d/gi, "")}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={{ md: 6 }}>
                <Grid item md={12} xs={12} sm={12} sx={{ textAlign: "start" }}>
                  <Field name="account_name">
                    {({ field, meta }) => (
                      <FormikControl
                        required="*"
                        control="input"
                        label="Account name"
                        name="account_name"
                        type="text"
                        meta={meta}
                        field={field}
                        value={formik.values.account_name.replace(/\d/gi, "")}
                        onChange={formik.handleChange("account_name")}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={{ md: 6 }} sx={{ pb: 3 }}>
                <Grid item md={12} xs={12} sm={12} sx={{ textAlign: "start" }}>
                  <Field name="BVN">
                    {({ field, meta }) => (
                      <FormikControl
                        required="*"
                        control="input"
                        label="BVN"
                        name="BVN"
                        value={formik.values.BVN.replace(/[^0-9]/gi, "")}
                        meta={meta}
                        field={field}
                        maxLength={11}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <CustomButton disabled={!formik.isValid || formik.isSubmitting} />
              <PersistFormikValues
                name="bank-details"
                storage="sessionStorage"
              />
            </Form>
          );
        }}
      </Formik>
    </LoanRequestLayout>
  );
};
