import { Box, Typography } from "@mui/material";
import { LandingPageStyle } from "../LandingPageStyle";
import { PauseOnHover } from "./Carousel";

export const LoanBenefits = () => {
  return (
    <Box sx={LandingPageStyle().LoanBenefitBox1}>
      <Box sx={LandingPageStyle().LoanBenefitBox2}>
        <Typography variant="h3" sx={LandingPageStyle().LoanBenefitTypography1}>
          Benefits
        </Typography>

        <PauseOnHover />
      </Box>
    </Box>
  );
};
