import { http } from "plugins/Axios";

export const createGuarantorRequest = async (payload) => {
  http.post("/revenue/loan-guarantors/", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

