import { http } from "plugins/Axios";

export const createLoanRequest = async (payload) => {
  return http.post("/revenue/loan-applicants/", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getApplicantDetails = async (id) => {
  return http.get(`/revenue/loan-applicants/${id}`);
};
