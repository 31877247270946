import { Button, Box, Typography } from "@mui/material";
import { useState } from "react";
import uploadIcon from "../../../assets/images/file-upload.png";

export const UploadFileName = ({ fileTitle, onChange, label, name }) => {
  const [file, setFile] = useState({
    raw: null,
    displayImage: null,
  });
  const [error, setError] = useState(null);

  const resetState = () => {
    // reset state in child
    setFile({
      raw: null,
      displayImage: null,
    });

    setError(null);

    // let parent know to reset state also
    onChange(null);
  };

  const onFileUpload = ({ target }) => {
    resetState();
    const file = target.files?.[0];

    if (!file) {
      setError("Invalid file");
      return;
    }

    const filesize = (file.size / 1024 / 1024).toFixed(4);
    const fileisToolarge = filesize > 0.1;

    if (fileisToolarge) {
      setError("File too large");
      return;
    }

    setFile({
      raw: file,
      displayImage: URL.createObjectURL(file),
    });
    onChange(file);
  };

  return (
    <Box sx={{ fontFamily: "Poppins" }}>
      <Typography variant="label" component="label" htmlFor={name}>
        <Typography component="span" sx={{ color: "red" }}>
          *
        </Typography>
        {label}
      </Typography>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="2px"
        sx={{
          backgroundColor: "rgba(0,0,0,0.04)",
          border: "none",
          width: "100%",
          height: "50px",
          margin: "1rem 0 0 0",
        }}
      >
        <Box
          color={error ? "red" : "#8E8E93"}
          sx={{
            overflow: "hidden",
            fontSize: { xs: "0.7rem", sm: "0.7rem" },
            width: "50%",
            paddingLeft: { xs: "1rem", sm: "1rem" },
          }}
        >
          {error ?? file.raw?.name ?? fileTitle}
        </Box>

        <Button component="label">
          <Box component="img" src={uploadIcon} alt="upload" />
          <input
            hidden
            accept="image/png, image/jpeg"
            type="file"
            onChange={onFileUpload}
          />
        </Button>
      </Box>
    </Box>
  );
};
