import { Box, Typography, Container, Paper } from "@mui/material";
import feedbackImage from "assets/images/people2 1.png";
import feedbackImage2 from "assets/images/Asset 2 1.png";
import { FeedbackStyles } from "./FeedbackStyles";

export const Feedback = () => {
  return (
    <Container maxWidth="sm">
      <Paper sx={FeedbackStyles.paper}>
        <Box component="img" src={feedbackImage2} sx={FeedbackStyles.Box1} />
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={FeedbackStyles.Typography1}>
            Your application has been received!
          </Typography>
          <Typography sx={FeedbackStyles.Typography2}>
            We're reviewing it and will reach out to you within 24 hours with
            next steps
          </Typography>
        </Box>
        <Box component="img" src={feedbackImage} sx={FeedbackStyles.Box2} />
      </Paper>
      <Typography sx={FeedbackStyles.Typography3}>
        Need help? Email us at
        <Typography
          component="span"
          sx={FeedbackStyles.Typography4}
        >
          {" "}
          launchpad@dukka.com or call 01 887 8000
        </Typography>
      </Typography>
    </Container>
  );
};
