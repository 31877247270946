import { Box, Paper, Divider } from "@mui/material";
import { Formik, Form } from "formik";
import { useLoanContext } from "context/LoanContext/LoanContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { CustomButton } from "components/Button/Button";
import {
  formOneInitialValues,
  formOneValidationSchema,
} from "./ApplicantFormSchema";
import { PersistFormikValues } from "formik-persist-values";
import { LoanDetails } from "components/LoanRequest/LoanDetails/LoanDetails";
import { BusinessDetails } from "components/LoanRequest/BusinessDetails/BusinessDetails";
import { PersonalDetails } from "components/LoanRequest/PersonalDetails/PersonalDetails";
import { GuarantorsDetails } from "components/LoanRequest/GuarantorsDetails/GuarantorsDetails";
import {
  calculateTotalAmountWithInterest,
  dateToBackendFormat,
  calculateMonthlyRepayment,
  calculateRepaymentDate,
} from "../../components/LoanRequest/LoanDetails/LoanDetailsUtils";

export const ApplicantForm = () => {
  const navigate = useNavigate();
  const { setLoanRequest, loanRequest } = useLoanContext();

  const onSubmit = (values) => {
    const firstRepaymentDate = calculateRepaymentDate(
      values.loanDetails.date_needed,
      (values.loanDetails.loan_duration = "30 Days")
    ).split("-");
    const secondRepaymentDate = calculateRepaymentDate(
      values.loanDetails.date_needed,
      (values.loanDetails.loan_duration = "60 Days")
    ).split("-");
    const thirdRepaymentDate = calculateRepaymentDate(
      values.loanDetails.date_needed,
      (values.loanDetails.loan_duration = "90 Days")
    ).split("-");
    const personalPayload = {
      ...values.personalDetails,
      date_of_birth: dayjs(
        new Date(values.personalDetails.date_of_birth)
      ).format("YYYY-MM-DD"),
    };
    const loanPayload = {
      ...values.loanDetails,
      total_repayment_amount: calculateTotalAmountWithInterest(
        values.loanDetails.loan_amount_needed,
        values.loanDetails.loan_duration
      ),
      monthly_repayment_amount: calculateMonthlyRepayment(
        values.loanDetails.loan_duration,
        values.loanDetails.loan_amount_needed
      ),

      first_repayment_date: `${firstRepaymentDate[2]}-${firstRepaymentDate[1]}-${firstRepaymentDate[0]}`,
      second_repayment_date: `${secondRepaymentDate[2]}-${secondRepaymentDate[1]}-${secondRepaymentDate[0]}`,
      third_repayment_date: `${thirdRepaymentDate[2]}-${thirdRepaymentDate[1]}-${thirdRepaymentDate[0]}`,

      date_needed: dateToBackendFormat(values.loanDetails.date_needed),

      repayment_amount: calculateTotalAmountWithInterest(
        values.loanDetails.loan_amount_needed,
        values.loanDetails.loan_duration
      ),
    };
    setLoanRequest({
      ...loanRequest,
      ...values.businessDetails,
      ...personalPayload,
      ...loanPayload,
      ...values.guarantorDetails,
    });

    navigate("/terms-and-conditions");
  };

  return (
    <Box
      sx={{
        paddingTop: { md: 12, xs: 12 },
        width: { xs: "90%", md: "70%" },
        marginInline: "auto",
        marginTop: { md: "1rem " },
        marginBottom: { md: "3rem " },
      }}
    >
      <Paper
        sx={{
          bgcolor: "transparent",
          padding: { md: "2rem", xs: "2rem 1rem" },
          border: "1px solid #E3E3E3",
          boxShadow: "0px 20px 45px rgba(0, 0, 0, 0.05)",
          borderRadius: "10px",
          margin: {
            xs: "0 auto",
          },
        }}
      >
        <Formik
          initialValues={formOneInitialValues}
          validationSchema={formOneValidationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <Box>
                  <BusinessDetails value={formik.values.businessDetails} />
                </Box>
                <Divider sx={{ margin: "3rem 0" }} />
                <Box>
                  <PersonalDetails
                    name="personalDetails"
                    value={formik.values.personalDetails}
                  />
                </Box>
                <Box>
                  <LoanDetails
                    name="loanDetails"
                    value={formik.values.loanDetails}
                  />
                </Box>
                <Divider sx={{ margin: "3rem 0" }} />

                <Box>
                  <GuarantorsDetails
                    name="guarantorDetails"
                    value={formik.values.guarantorDetails}
                  />
                </Box>
                <CustomButton
                  text="submit"
                  disabled={formik.isSubmitting}
                  // disabled={!formik.dirty || formik.isSubmitting}
                />

                <PersistFormikValues
                  name="applicant-values"
                  storage="sessionStorage"
                />
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </Box>
  );
};
