import { Box, Typography } from '@mui/material';
import { Field , ErrorMessage} from 'formik';
import ErrorMsg from './ErrorMsg';
import React from 'react'

export const Checkbox = (props) => {
    const {label, name,value,id, ...rest} = props;



  return (
    <Box>
          <Field name={name} {...rest}>
        {({ field }) => {
         
            return (
              <Box >
                <input
                  type="checkbox"
                  id={name}
                  {...field}
                  value={value}
                  checked={field.value === true}
                />
                <Typography htmlFor={name} style={{marginLeft:"1rem",fontFamily:"Poppins",display:"inline", fontSize:"1rem"}}>{label}</Typography>
                <ErrorMessage name={name} component={ErrorMsg}   />
              </Box>
            );
       
        }}
      </Field>
    </Box>
  )
}
