import { Button, Box, Typography } from "@mui/material";
import defaultImage from "assets/images/image-placeholder.png";
import { useState } from "react";

export const UploadID = ({ fileTitle, onChange, name, required, label }) => {
  const [file, setFile] = useState({
    raw: null,
    displayImage: null,
  });
  const [error, setError] = useState(null);

  const resetState = () => {
    // reset state in child
    setFile({
      raw: null,
      displayImage: null,
    });

    setError(null);

    // let parent know to reset state also
    onChange(null);
  };

  const onFileUpload = ({ target }) => {
    resetState();
    const file = target.files?.[0];

    if (!file) {
      setError("Invalid file");
      return;
    }

    const filesize = (file.size / 1024 / 1024).toFixed(4);
    const fileisToolarge = filesize > 0.2;

    if (fileisToolarge) {
      setError("File too large");
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setFile({
      raw: file,
      displayImage: imageUrl,
    });
    onChange(file);
  };

  return (
    <>
      <Typography component="label" htmlFor={name} sx={{ fontSize: "1rem" }}>
        <Typography component="span" sx={{ color: "red" }}>
          {required}
        </Typography>
        {label}
      </Typography>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="2px"
        border="1px dashed rgba(0, 0, 0, 0.2)"
        py="14px"
        px="20px"
        mb="20px"
        sx={{
          marginTop: "1rem",
        }}
      >
        <Box
          component="img"
          src={file?.displayImage ?? defaultImage}
          width="80px"
          height="80px"
          alt="placeholder"
        />
        <Box
          color={error ? "red" : "#8E8E93"}
          sx={{
            fontSize: { xs: "0.5rem", sm: "1rem" },
            overflow: "hidden",
            width: "50%",
            paddingLeft: { xs: "0.5rem", sm: "0" },
            textAlign: "center",
          }}
        >
          {error ?? file.raw?.name ?? fileTitle}
        </Box>

        <Button
          variant="outlined"
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "3px",
            textTransform: "capitalize",
          }}
          component="label"
        >
          Browse
          <input
            hidden
            accept="image/png, image/jpeg, image/jpg"
            multiple
            type="file"
            onChange={onFileUpload}
          />
        </Button>
      </Box>
    </>
  );
};
