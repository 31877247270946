import dayjs from "dayjs";




const LOAN_INTEREST = 0.05;

const loanOptions = [
  { label: "30 Days", value: "30 Days" },
  { label: "60 Days", value: "60 Days" },
  { label: "90 Days", value: "90 Days" },
];

const loanStatusOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
// const LOAN_INTEREST = 0.05;


// const calculateTotalAmountWithInterest = (loan_amount_needed) => {
//   const loanAmount = Number(loan_amount_needed);
//   const interest = Number(loanAmount) * Number(LOAN_INTEREST);
//   const amount = loanAmount + interest;
//   return amount;
// };
// const convertLoanDurationToMonth = (loan_duration) => {
//   const days =
//   typeof loan_duration === "string"
//   ? Number(loan_duration.split(" ")[0])
//   : [];
//   return days / 30;
// };

// const calculateMonthlyRepayment = (loan_duration, loan_amount_needed) => {
//   const duration = convertLoanDurationToMonth(loan_duration);
//   const totalAmountPayable =
//     calculateTotalAmountWithInterest(loan_amount_needed);
//   const amount = Math.ceil(totalAmountPayable / duration);
//   return amount;
// };


const calculateTotalAmountWithInterest = (
  loan_amount_needed,
  loan_duration
) => {
  const loanAmount = Number(loan_amount_needed);
  let interestRate;

  switch (loan_duration) {
    case "30 Days":
      interestRate = 0.05;
      break;
    case "60 Days":
      interestRate = 0.1;
      break;
    case "90 Days":
      interestRate = 0.15;
      break;
    default:
      interestRate = 0.05; // Default to 5% if duration is not specified or unrecognized

  }

  const interest = loanAmount * interestRate;
  const amount = loanAmount + interest;
  return amount;
};

const convertLoanDurationToMonth = (loan_duration) => {
  const days =
    typeof loan_duration === "string"
      ? Number(loan_duration.split(" ")[0])
      : [];
  return days / 30;
};

const calculateMonthlyRepayment = (loan_duration, loan_amount_needed) => {
  const duration = convertLoanDurationToMonth(loan_duration);
  const totalAmountPayable = calculateTotalAmountWithInterest(
    loan_amount_needed,
    loan_duration
  );
  const amount = Math.ceil(totalAmountPayable / duration);
  return amount;
};

const currencyConverter = (amount) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    maximumSignificantDigits: 4,
  }).format(amount);

const dateToBackendFormat = (date) => dayjs(date).format("YYYY-MM-DD");

const calculateRepaymentDate = (date_needed, loan_duration) => {
  const duration = convertLoanDurationToMonth(loan_duration);
  let dateOfRequest = new Date(date_needed);
  const repaymentDate = dateOfRequest.setMonth(
    dateOfRequest.getMonth() + duration
  );
  const updatedDate = dayjs(new Date(repaymentDate)).format("DD-MM-YYYY");
  return updatedDate;
};
export {
  calculateTotalAmountWithInterest,
  currencyConverter,
  dateToBackendFormat,
  calculateMonthlyRepayment,
  calculateRepaymentDate,
  loanOptions,
  loanStatusOptions,
};
