import { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { Grid, Box } from "@mui/material";
import { FormikControl } from "components/Forms/FormikControl/FormikControl";
import { PersistFormikValues } from "formik-persist-values";
import {
  calculateTotalAmountWithInterest,
  currencyConverter,
  calculateMonthlyRepayment,
  calculateRepaymentDate,
  loanOptions,
  loanStatusOptions,
} from "./LoanDetailsUtils";

export const LoanDetails = () => {
  const formik = useFormikContext();


  return (
    <Box>
      <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
        <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
          <Field name="loanDetails.loan_amount_needed">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                control="input"
                label="Amount needed"
                name="loanDetails.loan_amount_needed"
                helperText="Between 10,000 - 200,000"
                value={currencyConverter(
                  Number(formik.values.loanDetails.loan_amount_needed)
                )}
                onChange={(e) => {
                  // formik.handleChange("loanDetails.loan_amount_needed");
                  const toNum = Number(e.target.value.replace(/[^0-9]+/g, ""));
                  formik.setFieldValue("loanDetails.loan_amount_needed", toNum);
                }}
                meta={meta}
                field={field}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
          <Field name="loanDetails.loan_duration">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                control="select"
                label="Loan duration"
                name="loanDetails.loan_duration"
                placeholder="30days"
                options={loanOptions}
                value={formik.values.loanDetails.loan_duration}
                meta={meta}
                field={field}
                // onChange={formik.handleChange("loanDetails.loan_duration")}
                onChange={(selectedValue) => {
                  formik.setFieldValue(
                    "loanDetails.loan_duration",
                    selectedValue.target.value
                  );
                }}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      {formik.values.loanDetails.loan_amount_needed &&
        formik.values.loanDetails.loan_duration === "30 Days" && (
          <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
            <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
              <Field name="loanDetails.monthly_repayment_amount">
                {({ field, meta }) => (
                  <FormikControl
                    control="input"
                    label="1st month repayment"
                    name="loanDetails.monthly_repayment_amount"
                    meta={meta}
                    field={field}
                    value={currencyConverter(
                      calculateMonthlyRepayment(
                        formik.values.loanDetails.loan_duration,
                        formik.values.loanDetails.loan_amount_needed
                      )
                    )}
                    onChange={(e) => {
                      // formik.handleChange("monthly_repayment_amount");
                      const toNum = Number(
                        e.target.value.replace(/[^0-9]+/g, "")
                      );
                      formik.setFieldValue(
                        "loanDetails.monthly_repayment_amount",
                        toNum
                      );
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
              <Field name="loanDetails.date_needed">
                {({ field, meta }) => (
                  <FormikControl
                    disablePast
                    required="*"
                    control="date"
                    label="Date needed"
                    name="loanDetails.date_needed"
                    type="date"
                    value={formik.values.loanDetails.date_needed}
                    onChange={(date) => {
                      formik.setFieldValue("loanDetails.date_needed", date);
                    }}
                    meta={meta}
                    field={field}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        )}
      {formik.values.loanDetails.loan_amount_needed &&
        formik.values.loanDetails.loan_duration === "60 Days" && (
          <>
            <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
              <Grid item md={12} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.date_needed">
                  {({ field, meta }) => (
                    <FormikControl
                      disablePast
                      required="*"
                      control="date"
                      label="Date needed"
                      name="loanDetails.date_needed"
                      type="date"
                      value={formik.values.loanDetails.date_needed}
                      onChange={(date) => {
                        formik.setFieldValue("loanDetails.date_needed", date);
                      }}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.monthly_repayment_amount">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="1st month repayment"
                      name="loanDetails.monthly_repayment_amount"
                      meta={meta}
                      field={field}
                      value={currencyConverter(
                        calculateMonthlyRepayment(
                          formik.values.loanDetails.loan_duration,
                          formik.values.loanDetails.loan_amount_needed
                        )
                      )}
                      onChange={(e) => {
                        // formik.handleChange("monthly_repayment_amount");
                        const toNum = Number(
                          e.target.value.replace(/[^0-9]+/g, "")
                        );
                        formik.setFieldValue(
                          "loanDetails.monthly_repayment_amount",
                          toNum
                        );
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.first_repayment_date">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="1st Repayment date"
                      name="loanDetails.first_repayment_date"
                      value={calculateRepaymentDate(
                        formik.values.loanDetails.date_needed,
                        (formik.values.loanDetails.loan_duration = "30 Days")
                      )}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.monthly_repayment_amount">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="2nd month repayment"
                      name="loanDetails.monthly_repayment_amount"
                      meta={meta}
                      field={field}
                      value={currencyConverter(
                        calculateMonthlyRepayment(
                          (formik.values.loanDetails.loan_duration = "60 Days"),
                          formik.values.loanDetails.loan_amount_needed
                        )
                      )}
                      onChange={(e) => {
                        // formik.handleChange("loanDetailsmonthly_repayment_amount");
                        const toNum = Number(
                          e.target.value.replace(/[^0-9]+/g, "")
                        );
                        formik.setFieldValue(
                          "loanDetails.monthly_repayment_amount",
                          toNum
                        );
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.second_repayment_date">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="2nd Repayment date"
                      name="loanDetails.second_repayment_date"
                      value={calculateRepaymentDate(
                        formik.values.loanDetails.date_needed,
                        (formik.values.loanDetails.loan_duration = "60 Days")
                      )}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </>
        )}
      {formik.values.loanDetails.loan_amount_needed &&
        formik.values.loanDetails.loan_duration === "90 Days" && (
          <>
            <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
              <Grid item md={12} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.date_needed">
                  {({ field, meta }) => (
                    <FormikControl
                      disablePast
                      required="*"
                      control="date"
                      label="Date needed"
                      name="loanDetails.date_needed"
                      type="date"
                      value={formik.values.loanDetails.date_needed}
                      onChange={(date) => {
                        formik.setFieldValue("loanDetails.date_needed", date);
                      }}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.monthly_repayment_amount">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="1st month repayment"
                      name="loanDetails.monthly_repayment_amount"
                      meta={meta}
                      field={field}
                      value={currencyConverter(
                        calculateMonthlyRepayment(
                          formik.values.loanDetails.loan_duration,
                          formik.values.loanDetails.loan_amount_needed
                        )
                      )}
                      onChange={(e) => {
                        // formik.handleChange("loanDetails.monthly_repayment_amount");
                        const toNum = Number(
                          e.target.value.replace(/[^0-9]+/g, "")
                        );
                        formik.setFieldValue(
                          "loanDetails.monthly_repayment_amount",
                          toNum
                        );
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.first_repayment_date">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="1st Repayment date"
                      name="loanDetails.first_repayment_date"
                      value={calculateRepaymentDate(
                        formik.values.loanDetails.date_needed,
                        (formik.values.loanDetails.loan_duration = "30 Days")
                      )}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.monthly_repayment_amount">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="2nd month repayment"
                      name="loanDetails.monthly_repayment_amount"
                      meta={meta}
                      field={field}
                      value={currencyConverter(
                        calculateMonthlyRepayment(
                          (formik.values.loanDetails.loan_duration = "90 Days"),
                          formik.values.loanDetails.loan_amount_needed
                        )
                      )}
                      onChange={(e) => {
                        // formik.handleChange("monthly_repayment_amount");
                        const toNum = Number(
                          e.target.value.replace(/[^0-9]+/g, "")
                        );
                        formik.setFieldValue(
                          "loanDetails.monthly_repayment_amount",
                          toNum
                        );
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.second_repayment_date">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="2nd Repayment date"
                      name="loanDetails.second_repayment_date"
                      value={calculateRepaymentDate(
                        formik.values.loanDetails.date_needed,
                        (formik.values.loanDetails.loan_duration = "60 Days")
                      )}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.monthly_repayment_amount">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="3rd month repayment"
                      name="loanDetails.monthly_repayment_amount"
                      meta={meta}
                      field={field}
                      value={currencyConverter(
                        calculateMonthlyRepayment(
                          (formik.values.loanDetails.loan_duration = "90 Days"),
                          formik.values.loanDetails.loan_amount_needed
                        )
                      )}
                      onChange={(e) => {
                        const toNum = Number(
                          e.target.value.replace(/[^0-9]+/g, "")
                        );
                        formik.setFieldValue(
                          "loanDetails.monthly_repayment_amount",
                          toNum
                        );
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
                <Field name="loanDetails.third_repayment_date">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="3rd Repayment date"
                      name="loanDetails.third_repayment_date"
                      value={calculateRepaymentDate(
                        formik.values.loanDetails.date_needed,
                        (formik.values.loanDetails.loan_duration = "90 Days")
                      )}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </>
        )}

      {formik.values.loanDetails.loan_amount_needed &&
      formik.values.loanDetails.loan_duration === "60 Days" ? null : (
        <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
          {formik.values.loanDetails.loan_amount_needed &&
          formik.values.loanDetails.loan_duration === "90 Days" ? (
            <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
              <Field name="loanDetails.does_business_have_running_loans">
                {({ field, meta }) => (
                  <FormikControl
                    required="*"
                    control="radio"
                    label="Do you have any running loans?"
                    options={loanStatusOptions}
                    name="loanDetails.does_business_have_running_loans"
                    value={
                      formik.values.loanDetails.does_business_have_running_loans
                    }
                    onChange={formik.handleChange(
                      "loanDetails.does_business_have_running_loans"
                    )}
                    meta={meta}
                    field={field}
                  />
                )}
              </Field>
            </Grid>
          ) : (
            <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
              {formik.values.loanDetails.loan_amount_needed &&
              formik.values.loanDetails.loan_duration === "30 Days" ? (
                <Field name="loanDetails.first_repayment_date">
                  {({ field, meta }) => (
                    <FormikControl
                      control="input"
                      label="Repayment date"
                      name="loanDetails.first_repayment_date"
                      value={calculateRepaymentDate(
                        formik.values.loanDetails.date_needed,
                        (formik.values.loanDetails.loan_duration = "30 Days")
                      )}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              ) : (
                <Field name="loanDetails.date_needed">
                  {({ field, meta }) => (
                    <FormikControl
                      disablePast
                      required="*"
                      control="date"
                      label="Date needed"
                      name="loanDetails.date_needed"
                      type="date"
                      value={formik.values.loanDetails.date_needed}
                      onChange={(date) => {
                        formik.setFieldValue("loanDetails.date_needed", date);
                      }}
                      meta={meta}
                      field={field}
                    />
                  )}
                </Field>
              )}
            </Grid>
          )}
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="loanDetails.total_repayment_amount">
              {({ field, meta }) => (
                <FormikControl
                  control="input"
                  label="Total repayment"
                  name="loanDetails.total_repayment_amount"
                  meta={meta}
                  field={field}
                  value={
                    (formik.values.loanDetails.total_repayment =
                      currencyConverter(
                        calculateTotalAmountWithInterest(
                          formik.values.loanDetails.loan_amount_needed,
                          formik.values.loanDetails.loan_duration
                        )
                      ))
                  }
                  onChange={(e) => {
                    const toNum = Number(
                      e.target.value.replace(/[^0-9]+/g, "")
                    );
                    formik.setFieldValue(
                      "loanDetails.total_repayment_amount",
                      toNum
                    );
                  }}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
        <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
          {formik.values.loanDetails.loan_amount_needed &&
          formik.values.loanDetails.loan_duration === "90 Days" ? null : (
            <Field name="loanDetails.does_business_have_running_loans">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="radio"
                  label="Do you have any running loans?"
                  options={loanStatusOptions}
                  name="loanDetails.does_business_have_running_loans"
                  value={
                    formik.values.loanDetails.does_business_have_running_loans
                  }
                  onChange={formik.handleChange(
                    "loanDetails.does_business_have_running_loans"
                  )}
                  meta={meta}
                  field={field}
                />
              )}
            </Field>
          )}
        </Grid>
        {formik.values.loanDetails.loan_amount_needed &&
          formik.values.loanDetails.loan_duration === "60 Days" && (
            <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
              <Field name="loanDetails.does_business_have_running_loans">
                {({ field, meta }) => (
                  <Field name="loanDetails.repayment_amount">
                    {({ field, meta, form }) => (
                      <FormikControl
                        required="*"
                        control="input"
                        label="Total repayment"
                        name="loanDetails.repayment_amount"
                        value={
                          (formik.values.loanDetails.total_repayment =
                            currencyConverter(
                              calculateTotalAmountWithInterest(
                                formik.values.loanDetails.loan_amount_needed,
                                formik.values.loanDetails.loan_duration
                              )
                            ))
                        }
                        onChange={(e) => {
                          // formik.handleChange("repayment_amount");
                          const toNum = Number(
                            e.target.value.replace(/[^0-9]+/g, "")
                          );
                          formik.setFieldValue(
                            "loanDetails.repayment_amount",
                            toNum
                          );
                        }}
                        meta={meta}
                        field={field}
                      />
                    )}
                  </Field>
                )}
              </Field>
            </Grid>
          )}
      </Grid>
      {formik.values.loanDetails.does_business_have_running_loans === "Yes" && (
        <Grid container spacing={{ md: 6 }} sx={{ pb: 1 }}>
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="loanDetails.amount_outstanding">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="input"
                  label="How much outstanding?"
                  name="loanDetails.amount_outstanding"
                  value={formik.values.loanDetails.amount_outstanding.replace(
                    /[^0-9]/gi,
                    ""
                  )}
                  meta={meta}
                  field={field}
                />
              )}
            </Field>
          </Grid>
          <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
            <Field name="loanDetails.loan_company">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="input"
                  label="What loan company?"
                  name="loanDetails.loan_company"
                  type="text"
                  meta={meta}
                  field={field}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      )}
      <PersistFormikValues
        name="loan-details"
        ignoreValues="date_of_birth"
        storage="sessionStorage"
      />
    </Box>
  );
};
