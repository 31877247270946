import * as Yup from "yup";

const accountNumberRegex = /^[0-9]{9,18}$/
const bankNames = [
  { label: "Select...", value: "" },
  { label: "Access Bank Plc", value: "accessbank" },
  { label: "Citibank Nigeria Limited", value: "citybank" },
  { label: "Ecobank Nigeria Plc", value: "ecobank" },
  { label: "Fidelity Bank Plc", value: "fidelitybank" },
  { label: "FIRST BANK NIGERIA LIMITED", value: "firstbank" },
  { label: "First City Monument Bank Plc", value: "firstmonument" },
  { label: "Globus Bank Limited", value: "globus" },
  { label: "Guaranty Trust Bank Plc", value: "gtb" },
  { label: "Heritage Banking Company Ltd.", value: "hbc" },
  { label: "Keystone Bank Limited", value: "keystone" },
  { label: "Parallex Bank Ltd", value: "parallex" },
  { label: "Polaris Bank Plc", value: "polaris" },
  { label: "Premium Trust Bank", value: "premium" },
  { label: "Providus Bank", value: "providus" },
  { label: "STANBIC IBTC BANK PLC", value: "stanbic" },
  { label: "Standard Chartered Bank Nigeria Ltd", value: "scbn" },
  { label: "SunTrust Bank Nigeria Limited", value: "suntrust" },
  { label: "Titan Trust Bank Ltd", value: "titans" },
  { label: "Union Bank of Nigeria Plc", value: "union" },
  { label: "United Bank For Africa Plc", value: "uba" },
  { label: "Unity Bank Plc", value: "unity" },
  { label: "Wema Bank Plc", value: "wema" },
  { label: "Zenith Bank Plc", value: "zenith" },
  { label: "Others", value: "others" },
  { label: "Sterling Bank Plc", value: "sterling" },
];

 const initialValues = {
   account_number: "",
   bank_name: "",
   other_bank: "",
   account_name: "",
   BVN: "",
 };

 const validationSchema = Yup.object({
   account_number: Yup.string()
     .matches(accountNumberRegex, "Enter a valid account number")
     .required("This field is required"),
   bank_name: Yup.string().required("This field is required"),
   other_bank:Yup.string().when("bank_name", {
    is: "others",
    then: Yup.string().required("This field is required"),
  }),
   account_name: Yup.string().required("This field is required"),
   BVN: Yup.string().matches(/^[0-9]{11}$/, "Please enter a valid bvn number")
     .required("This field is required")
    
 });

 export {initialValues, validationSchema, bankNames}