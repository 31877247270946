export const FeedbackStyles = {
  paper: {
    bgcolor: "#fff",
    padding: { md: "6rem", xs: "3rem" },
    border: "1px solid #E3E3E3",
    boxShadow: "0px 20px 45px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    marginTop: 20,
    marginBottom: 20,
    position: "relative",
  },
  Box1: {
    width: { xs: "70px", md: "initial" },
    position: "absolute",
    top: { xs: "-60px", md: "-100px" },
    right: { xs: "-10px", md: "-50px" },
    objectFit: "cover",
  },
  Typography1: {
    fontWeight: 500,
    fontSize: { md: "32px", xs: "25px" },
    lineHeight: "33px",
    paddingBottom: "2",
  },
  Typography2: {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
  },
  Box2: {
    width: { xs: "90px", md: "initial" },
    position: "absolute",
    bottom: { xs: "-20px", md: "-30px" },
    left: { xs: "-20px", md: "-100px" },
  },
  Typography3: {
    fontWeight: 500,
    fontSize: "12px",
    lineGeight: "40px",
    pb: 6,
  },
  Typography4: {
    fontWeight: "bold",
    textAlign: "center",
  },
};
