import * as Yup from "yup";
const registerOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const membershipOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
const insuranceOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const businessOptions = [
  { label: "select...", value: "" },
  { label: "Online Store", value: "Online Store" },
  { label: "Cafe & Restaurants", value: "Cafe & Restaurants" },
  { label: "Baby Toys & Gifts", value: "Baby Toys & Gifts" },
  { label: "Food & Drink", value: "Food & Drink" },
  { label: "Home & Office", value: "Home & Office" },
  { label: "Service & Consulting", value: "Service & Consulting" },
  { label: "Automotives", value: "Automotives" },
  { label: "Supermarket", value: "Supermarket" },
  { label: "Beauty & Personal care", value: "Beauty & Personal care" },
  { label: "Computers", value: "Computers" },
  { label: "Electronics", value: "Electronics" },
  { label: "Gamming", value: "Gamming" },
  { label: "Health & Pharmacy", value: "Health & Pharmacy" },
  { label: "Luggage", value: "Luggage" },
  { label: "Phones & Tablets", value: "Phones & Tablets" },
  { label: "Sports & Outdoor", value: "Sports & Outdoor" },
  { label: "Arts & Crafts", value: "Arts & Crafts" },
  { label: "Fashion", value: "Fashion" },
  { label: "Jewellery", value: "Jewellery" },
  { label: "Pets & accessories", value: "Pets & accessories" },
  { label: "Others", value: "Others" },
];
const bankNames = [
  { label: "Select...", value: "" },
  { label: "Access Bank Plc", value: "accessbank" },
  { label: "Citibank Nigeria Limited", value: "citybank" },
  { label: "Ecobank Nigeria Plc", value: "ecobank" },
  { label: "Fidelity Bank Plc", value: "fidelitybank" },
  { label: "FIRST BANK NIGERIA LIMITED", value: "firstbank" },
  { label: "First City Monument Bank Plc", value: "firstmonument" },
  { label: "Globus Bank Limited", value: "globus" },
  { label: "Guaranty Trust Bank Plc", value: "gtb" },
  { label: "Heritage Banking Company Ltd.", value: "hbc" },
  { label: "Keystone Bank Limited", value: "keystone" },
  { label: "Parallex Bank Ltd", value: "parallex" },
  { label: "Polaris Bank Plc", value: "polaris" },
  { label: "Premium Trust Bank", value: "premium" },
  { label: "Providus Bank", value: "providus" },
  { label: "STANBIC IBTC BANK PLC", value: "stanbic" },
  { label: "Standard Chartered Bank Nigeria Ltd", value: "scbn" },
  { label: "SunTrust Bank Nigeria Limited", value: "suntrust" },
  { label: "Titan Trust Bank Ltd", value: "titans" },
  { label: "Union Bank of Nigeria Plc", value: "union" },
  { label: "United Bank For Africa Plc", value: "uba" },
  { label: "Unity Bank Plc", value: "unity" },
  { label: "Wema Bank Plc", value: "wema" },
  { label: "Zenith Bank Plc", value: "zenith" },
  { label: "Others", value: "others" },
  { label: "Sterling Bank Plc", value: "sterling" },
];

const formOneInitialValues = {
  name_of_business: "",
  BVN: "",
  account_number: "",
  bank_name: "",
  is_business_registered: "",
  BN_CAC_number: "",
  is_business_member_of_union: "",
  name_of_union: "",
  is_business_insured: "",
  insurer_name: "",
};

// const formTwoInitialValues = {
//   is_business_registered: "",
//   BN_CAC_number: "",
//   is_business_member_of_union: "",
//   name_of_union: "",
//   is_business_insured: "",
//   insurer_name: "",
// };

const formOneValidationSchema = Yup.object({
  name_of_business: Yup.string().required("This field is required"),
  BVN: Yup.string()
    .matches(/^[0-9]{11}$/, "Please enter a valid bvn number")
    .required("This field is required"),
  account_number: Yup.string().required("This field is required"),
  // bank_name: Yup.string().required("This field is required"),
  is_business_registered: Yup.string().required("This field is required"),
  BN_CAC_number: Yup.string().when("is_business_registered", {
    is: "Yes",
    then: Yup.string().required("This field is required"),
  }),
  is_business_member_of_union: Yup.string().required("This field is required"),
  name_of_union: Yup.string().when("is_business_member_of_union", {
    is: "Yes",
    then: Yup.string().required("This field is required"),
  }),
  is_business_insured: Yup.string().required(" This field is required"),
  insurer_name: Yup.string().when("is_business_insured", {
    is: "Yes",
    then: Yup.string().required("This field is required"),
  }),
});

// const formTwoValidationSchema = Yup.object({
//   is_business_registered: Yup.string().required("This field is required"),
//   BN_CAC_number: Yup.string().when("is_business_registered", {
//     is: "Yes",
//     then: Yup.string().required("This field is required"),
//   }),
//   is_business_member_of_union: Yup.string().required("This field is required"),
//   name_of_union: Yup.string().when("is_business_member_of_union", {
//     is: "Yes",
//     then: Yup.string().required("This field is required"),
//   }),
//   is_business_insured: Yup.string().required(" This field is required"),
//   insurer_name: Yup.string().when("is_business_insured", {
//     is: "Yes",
//     then: Yup.string().required("This field is required"),
//   }),
// });

export {
  formOneInitialValues,
  // formTwoInitialValues,
  formOneValidationSchema,
  // formTwoValidationSchema,
  registerOptions,
  membershipOptions,
  insuranceOptions,
  businessOptions,
  bankNames,
};
