import { Box, Grid, Typography, Button } from "@mui/material";
import HeroImg from "assets/images/Rectangle 66.png";
import { Link } from "react-router-dom";
import { LandingPageStyle } from "../LandingPageStyle";
export const Hero = () => {
  return (
    <Box sx={{ marginTop: "3rem" }}>
      <Box sx={LandingPageStyle().WrapperBox}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ marginBottom: { xs: "3rem", sm: "0" } }}
          >
            <Typography variant="h3" sx={LandingPageStyle().HeroTitle}>
              Small Business
              <Box component="br" />
              Small Loans
            </Typography>
            <Typography sx={LandingPageStyle().HeroSubtitle}>
              Secure the financial stability and growth of your business with
              the best rates in the market
            </Typography>
            <Box
              component={Link}
              to="/applicant-form
              "
              sx={{ textDecoration: "none" }}
            >
              <Button sx={LandingPageStyle("#e67e81", "#E97D80").Button}>
                Apply now
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box
              component="img"
              src={HeroImg}
              alt="hero"
              sx={LandingPageStyle().HeroImgBox}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
