import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
export const ErrorModal = ({ errorMessage, openModal, handleClose }) => {
  function ErrorText() {
    for (const [key, value] of Object.entries(errorMessage.response.data)) {
      // console.log(key);
      // console.log(value.length);
      const val = [...value];
      // console.log(val);
      if (key === "referral_code") {
        return (
          <Box component="p" sx={{ fontWeight: 400, fontSize: "20px" }}>
            Invalid referral code
          </Box>
        );
      } else if (key === "guarantor_passport") {
        return (
          <Box component="p" sx={{ fontWeight: 500, fontSize: "25px" }}>
            upload your Guarantor's passport photograph
          </Box>
        );
      } else {
        return (
          <Box component="p" sx={{ fontWeight: 500, fontSize: "25px" }}>
            something went wrong, please try again later 😔
          </Box>
        );
      }
    }
  }

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "none" }}
      >
        <Box sx={style}>
          <ErrorOutlineIcon
            sx={{
              marginInline: "auto",
              width: "100%",
              fill: "#ec7e81",
              fontSize: "70px",
            }}
          />
          <Box
            id="modal-modal-title"
            component="div"
            sx={{ textAlign: "center", color: "#231F20", fontSize: "15px" }}
          >
            {errorMessage.response && ErrorText()}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="a"
              href="/applicant-form"
              sx={{
                marginTop: "1rem",
                textDecoration: "none",
                color: "#fff",
                fontSize: "1.5rem",
                cursor: "pointer",
                backgroundColor: "#ec7e81",
                padding: "0.5rem 1rem",
                borderRadius: "5px",
              }}
            >
              Try again
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
