import React from "react";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { theme } from "./UI/Theme";
import { LoanContextProvider } from "context/LoanContext/LoanContext";
import { AppRoute } from "Routes/Routes";


function App() {
  return (
    <LoanContextProvider>
      <ThemeProvider theme={theme}>
        <Box className="App">
          <AppRoute />
        </Box>
      </ThemeProvider>
    </LoanContextProvider>
  );
}

export default App;
