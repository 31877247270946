import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const registerOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const membershipOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
const insuranceOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const businessOptions = [
  { label: "select...", value: "" },
  { label: "Online Store", value: "Online Store" },
  { label: "Cafe & Restaurants", value: "Cafe & Restaurants" },
  { label: "Baby Toys & Gifts", value: "Baby Toys & Gifts" },
  { label: "Food & Drink", value: "Food & Drink" },
  { label: "Home & Office", value: "Home & Office" },
  { label: "Service & Consulting", value: "Service & Consulting" },
  { label: "Automotives", value: "Automotives" },
  { label: "Supermarket", value: "Supermarket" },
  { label: "Beauty & Personal care", value: "Beauty & Personal care" },
  { label: "Computers", value: "Computers" },
  { label: "Electronics", value: "Electronics" },
  { label: "Gamming", value: "Gamming" },
  { label: "Health & Pharmacy", value: "Health & Pharmacy" },
  { label: "Luggage", value: "Luggage" },
  { label: "Phones & Tablets", value: "Phones & Tablets" },
  { label: "Sports & Outdoor", value: "Sports & Outdoor" },
  { label: "Arts & Crafts", value: "Arts & Crafts" },
  { label: "Fashion", value: "Fashion" },
  { label: "Jewellery", value: "Jewellery" },
  { label: "Pets & accessories", value: "Pets & accessories" },
  { label: "Others", value: "Others" },
];
const bankNames = [
  { label: "Select...", value: "" },
  { label: "Access Bank Plc", value: "accessbank" },
  { label: "Citibank Nigeria Limited", value: "citybank" },
  { label: "Ecobank Nigeria Plc", value: "ecobank" },
  { label: "Fidelity Bank Plc", value: "fidelitybank" },
  { label: "FIRST BANK NIGERIA LIMITED", value: "firstbank" },
  { label: "First City Monument Bank Plc", value: "firstmonument" },
  { label: "Globus Bank Limited", value: "globus" },
  { label: "Guaranty Trust Bank Plc", value: "gtb" },
  { label: "Heritage Banking Company Ltd.", value: "hbc" },
  { label: "Keystone Bank Limited", value: "keystone" },
  { label: "Parallex Bank Ltd", value: "parallex" },
  { label: "Polaris Bank Plc", value: "polaris" },
  { label: "Premium Trust Bank", value: "premium" },
  { label: "Providus Bank", value: "providus" },
  { label: "STANBIC IBTC BANK PLC", value: "stanbic" },
  { label: "Standard Chartered Bank Nigeria Ltd", value: "scbn" },
  { label: "SunTrust Bank Nigeria Limited", value: "suntrust" },
  { label: "Titan Trust Bank Ltd", value: "titans" },
  { label: "Union Bank of Nigeria Plc", value: "union" },
  { label: "United Bank For Africa Plc", value: "uba" },
  { label: "Unity Bank Plc", value: "unity" },
  { label: "Wema Bank Plc", value: "wema" },
  { label: "Zenith Bank Plc", value: "zenith" },
  { label: "Others", value: "others" },
  { label: "Sterling Bank Plc", value: "sterling" },
];
const sexOptions = [
  { label: "select", value: "" },
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
];

const idOptions = [
  { label: "select...", value: "" },
  { label: "International Passport", value: "Passport" },
  { label: "Nigeria's Driver's license", value: "Driver's license" },
  { label: "Nigeria's Voter's card", value: "Voter's card" },
  { label: "NIMC Slip or NIN card", value: "NIN" },
];
const formOneInitialValues = {
  businessDetails: {
    name_of_business: "",
    BVN: "",
    account_number: "",
    bank_name: "",
    is_business_registered: "",
    BN_CAC_number: "",
    is_business_member_of_union: "",
    name_of_union: "",
    is_business_insured: "",
    insurer_name: "",
  },
  personalDetails: {
    full_name: "",
    date_of_birth: new Date("2005-12-31"),
    sex: "",
    home_address: "",
    means_of_identification: "",
    email: "",
    phone_number: "",
    means_of_identification_no: "",
    applicant_passport: null,
    means_of_identification_image: null,
  },
  loanDetails: {
    loan_amount_needed: "",
    loan_duration: "",
    monthly_repayment_amount: "",
    total_repayment_amount: "",
    date_needed: new Date(),
    first_repayment_date: "",
    second_repayment_date: "",
    third_repayment_date: "",
    repayment_amount: "",
    does_business_have_running_loans: "",
    amount_outstanding: "",
    loan_company: "",
  },
  guarantorDetails: {
    guarantor_full_name: "",
    guarantor_employment_status: "",
    guarantor_passport: "",
    guarantor_employer: "",
    guarantor_phone_number: "",
    guarantor_address: "",
    guarantor_email_address: "",
    other_employment_Status: "",
    referral_code: "",
  },
};


const formOneValidationSchema = Yup.object({
  businessDetails: Yup.object({
    name_of_business: Yup.string().required("This field is required"),
    BVN: Yup.string()
      .required("This field is required")
      .length(11, "BVN must have exactly 11 characters"),
    is_business_registered: Yup.string().required("This field is required"),
    BN_CAC_number: Yup.string().when("is_business_registered", {
      is: "Yes",
      then: Yup.string().required("This field is required"),
    }),
    is_business_member_of_union: Yup.string().required(
      "This field is required"
    ),
    name_of_union: Yup.string().when("is_business_member_of_union", {
      is: "Yes",
      then: Yup.string().required("This field is required"),
    }),
    is_business_insured: Yup.string().required("This field is required"),
    insurer_name: Yup.string().when("is_business_insured", {
      is: "Yes",
      then: Yup.string().required("This field is required"),
    }),
  }),
  personalDetails: Yup.object({
    full_name: Yup.string().required("This field is required"),
    date_of_birth: Yup.date().required("This field is required"),
    sex: Yup.string().required("This field is required"),
    home_address: Yup.string().required("This field is required"),
    means_of_identification: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("This field is required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .max(11)
      .required("This field is required"),
    means_of_identification_no: Yup.string()
      .matches(/^[0-9a-z]/gi, "Must be exactly must be a valid id number")
      .required("This field is required"),
    applicant_passport: Yup.mixed()
      .nullable()
      .required("This field is required"),

    means_of_identification_image: Yup.mixed()
      .nullable()
      .required("This field is required"),
  }),
  loanDetails: Yup.object({
    loan_amount_needed: Yup.number()
      .required("This field is required")
      .min(10000, "Please enter an amount between N10,000 - N200,000")
      .max(200000, "Please enter an amount between N10,000 - N200,000"),
    loan_duration: Yup.string().required("This field is required"),
    date_needed: Yup.date().required("This field is required"),
    does_business_have_running_loans: Yup.string().required(
      "This field is required"
    ),
    amount_outstanding: Yup.string().when("does_business_have_running_loans", {
      is: "Yes",
      then: Yup.string().required("This field is required"),
    }),
    loan_company: Yup.string().when("does_business_have_running_loans", {
      is: "Yes",
      then: Yup.string().required("This field is required"),
    }),
  }),
  guarantorDetails: Yup.object({
    guarantor_full_name: Yup.string().required("This field is required"),
    guarantor_passport: Yup.string().required("This field is required"),
    guarantor_employment_status: Yup.string().required(
      "This field is required"
    ),
    guarantor_employer: Yup.string().when("guarantor_employment_status", {
      is: "Employed",
      then: Yup.string().required("This field is required"),
    }),
    guarantor_phone_number: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("This field is required"),
    guarantor_address: Yup.string().required("This field is required"),
    guarantor_email_address: Yup.string()
      .email("Invalid email format")
      .required("This field is required"),
  }),
});


export {
  formOneInitialValues,
  formOneValidationSchema,
  registerOptions,
  membershipOptions,
  insuranceOptions,
  businessOptions,
  bankNames,
  sexOptions,
  idOptions,
};
