import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export const Error = () => {
  return (
    <Box sx={{ marginTop: "4rem" }}>
      <Box
        sx={{
          width: { xs: "90%", sm: "70%", md: "85%" },
          marginInline: "auto",
          padding: { xs: "3rem 0", md: "3rem 0 5rem 0" },
        }}
      >
        <Typography sx={{ textAlign: "center", fontSize: "2rem" }}>
          Page Not Found !
        </Typography>

        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography
              component="h1"
              sx={{
                color: "#ec7e81",
                fontSize: "6rem",
                display: "block",
                textAlign: "center",
                margin: "1rem 0",
              }}
            >
              404
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box>
              <Typography
                component="p"
                sx={{
                  color: "#505050",
                  lineHeight: "25px",
                  marginTop: {md:"2rem"},
                  width: { md: "90%" },
                  paddingLeft: { md: "1rem" },
                  fontSize: "1rem",
                }}
              >
                We're sorry, but we can't find the page you were looking for.
                It's probably some thing we've done wrong but now we know about
                it and we'll try to fix it. In the meantime,
              </Typography>

              <Box
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  paddingLeft: { md: "1rem" },
                }}
              >
                <Box
                  component="a"
                  href="https://dukka.com/"
                  sx={{
                    marginTop: "1rem",
                    textDecoration: "none",
                    color: "#505050",
                    display: "inline-block",
                    position: "relative",
                    fontSize: "1rem",
                    " &:after": {
                      content: "''",
                      position: "absolute",
                      width: "100%",
                      height: "2px",
                      backgroundColor: "#ec7e81",
                      bottom: "-3px", //to place it in bottom
                      left: 0,
                    },
                  }}
                >
                  Go to Home Page
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
