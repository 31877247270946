import React from "react";
import {
  Hero,
  LoanBenefits,
  LoanCriteria,
  LoanSteps,
} from "components/LandingPage";
import { Box } from "@mui/material";

export const HomePage = () => {

  // console.log(process.env)
  // console.log(process.env.REACT_APP_NOT_SECRET_CODE)
  return (
    <Box>
      <Hero />
      <LoanBenefits />
      <LoanSteps />
      <LoanCriteria />
    </Box>
  );
};
