import {
  Box,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import HeroImg from "assets/images/Rectangle 68.png";
import GreenCheck from "assets/images/Green-check.svg";
import { PersonalDetails } from "components/LoanRequest/PersonalDetails/PersonalDetails";
import { Link } from "react-router-dom";
import { LandingPageStyle } from "../LandingPageStyle";
import { ApplicantForm } from "pages/ApplicantForm/ApplicantForm";

export const LoanCriteria = () => {
  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Box sx={LandingPageStyle().LoanCriteriaBox}>
        <Grid
          container
          direction={{ xs: "column-reverse", sm: "initial" }}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6} sx={{ marginTop: { xs: "3rem", sm: "0" } }}>
            <Typography variant="h3" sx={LandingPageStyle().LoanCriteriaTitle}>
              Loan Criteria
            </Typography>
            <Box sx={{ margin: { md: "1rem 0", lg: "2rem 0" } }}>
              <List>
                <ListItem
                  disablePadding
                  alignItems="flex-start"
                  sx={LandingPageStyle().GreenCheckListItem}
                >
                  <ListItemAvatar sx={{ minWidth: "30px" }}>
                    <Avatar
                      alt="Green check"
                      src={GreenCheck}
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Download and sign up on the DUKKA app"
                    sx={{ display: "inline-block" }}
                  />
                </ListItem>

                <ListItem
                  disablePadding
                  alignItems="flex-start"
                  sx={LandingPageStyle().ListItem}
                >
                  <ListItemAvatar sx={{ minWidth: "30px" }}>
                    <Avatar
                      alt="Green check"
                      src={GreenCheck}
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Raise a minimum of 20 invoices/receipts on the app" />
                </ListItem>

                <ListItem
                  disablePadding
                  alignItems="flex-start"
                  sx={LandingPageStyle().ListItem}
                >
                  <ListItemAvatar sx={{ minWidth: "30px" }}>
                    <Avatar
                      alt="Green check"
                      src={GreenCheck}
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Have a minimum of 25 inventory items on the app" />
                </ListItem>

                <ListItem
                  disablePadding
                  alignItems="flex-start"
                  sx={LandingPageStyle().ListItem}
                >
                  <ListItemAvatar sx={{ minWidth: "30px" }}>
                    <Avatar
                      alt="Green check"
                      src={GreenCheck}
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Use the Dukka app for more than 30 days" />
                </ListItem>

                <ListItem
                  disablePadding
                  alignItems="flex-start"
                  sx={LandingPageStyle().ListItem}
                >
                  <ListItemAvatar sx={{ minWidth: "30px" }}>
                    <Avatar
                      alt="Green check"
                      src={GreenCheck}
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Maintain a minimum daily DUKKA balance of N1,000" />
                </ListItem>
              </List>
            </Box>
            <Box
              component={Link}
              to="/applicant-form"
              element={<ApplicantForm/>}
              sx={{ textDecoration: "none" }}
            >
              <Button sx={LandingPageStyle("black", "#231F20").Button}>
                Apply now
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box
              component="img"
              src={HeroImg}
              alt="hero"
              sx={LandingPageStyle().LoanCriteriaImg}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
