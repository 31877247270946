import { Typography, Box, Button } from "@mui/material";
import { Arrow } from "./Arrow";
import { Steps } from "./Steps";
import { Link } from "react-router-dom";
import { LandingPageStyle } from "../LandingPageStyle";

export const LoanSteps = (text, num) => {
  return (
    <Box sx={LandingPageStyle().LoanStepBox1}>
      <Box sx={LandingPageStyle().LoanStepBox2}>
        <Typography variant="h3" sx={LandingPageStyle().LoanStepTypography1}>
          Get a loan is 3 steps
        </Typography>

        <Box sx={LandingPageStyle().LoanStepBox3}>
          <Steps text="Application" num="1" />
          <Arrow />
          <Steps text="Verification" num="2" />
          <Arrow />
          <Steps text="Disbursement" num="3" />
        </Box>

        <Box
          component={Link}
          to="/applicant-form"
          sx={LandingPageStyle().LoanStepBox4}
        >
          <Button sx={LandingPageStyle("black", "#231F20").Button}>
            Apply now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
