import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import {
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoInstagram,
} from "react-icons/io5";
import miniLogo from "../../assets/images/dukka-mini-logo.svg";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  const footerColumn1 = [
    {
      label: "About",
      link: "https://dukka.com/about/",
    },
    {
      label: " Contact us",
      link: "https://dukka.com/contact/",
    },
    {
      label: "Ambassadors",
      link: "https://dukka.com/campus/",
    },
    {
      label: "Partners",
      link: "https://dukka.com/partners/",
    },
    {
      label: "Career",
      link: "https://dukka.com/career/",
    },
    {
      label: "Loan",
      link: "/",
    },
  ];
  const footerColumn2 = [
    {
      label: "Legal",
      link: "",
    },
    {
      label: "Terms",
      link: "https://dukka.com/terms/",
    },
    {
      label: "Balance T & C",
      link: "https://dukka.com/balance-terms-and-conditions/",
    },
    {
      label: "Terminal T & C",
      link: "https://dukka.com/terminal-terms-and-conditions/",
    },
    {
      label: "Privacy",
      link: "https://dukka.com/privacy/",
    },
    {
      label: "Product Manual",
      link: "https://dukka.com/product-manual/",
    },
  ];

  return (
    <Box
      component="footer"
      sx={{ backgroundColor: "#151218", padding: "3rem 0" }}
    >
      <Box sx={{ width: { xs: "90%", sm: "85%" }, marginInline: "auto" }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box sx={{ marginBottom: "2rem" }}>
              <Box
                component="img"
                src={miniLogo}
                alt="mini-logo"
                sx={{ height: "48px", marginBottom: "1rem", cursor: "pointer" }}
              />
              <Box sx={{ color: "#fff", fontSize: "1.5rem" }}>
                <ListItemButton
                  component="a"
                  href="https://twitter.com/trustDukka"
                  sx={{
                    display: "inline",
                    padding: "0 0",
                  }}
                >
                  <IoLogoTwitter />
                </ListItemButton>

                <ListItemButton
                  component="a"
                  href="https://www.instagram.com/trustdukka/"
                  sx={{
                    display: "inline",
                    padding: "0 0",
                  }}
                >
                  <IoLogoInstagram
                    style={{
                      margin: "0 0.5rem",
                      cursor: "pointer",
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  component="a"
                  href="https://www.linkedin.com/company/dukka"
                  sx={{
                    display: "inline",
                    padding: "0 0",
                  }}
                >
                  <IoLogoLinkedin />
                </ListItemButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              sx={{
                "& .MuiTypography-root": {
                  marginBottom: "0.6rem",
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                },
              }}
            >
              <Grid item xs={6} md={6}>
                <List
                  sx={{
                    "& .MuiListItem-root": {
                      color: "white",
                    },
                    cursor: "pointer",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary="Company"
                      sx={{
                        marginBottom: "1.5rem",
                        marginLeft: "1rem",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          height: " 1rem",
                          width: "1rem",
                          backgroundColor: "white",
                          borderRadius: "5px 5px 5px 15px",
                          margin: "7px -24px",
                        },
                      }}
                    />
                  </ListItem>

                  {footerColumn1.map(({ label, link }, index) => {
                    return (
                      <ListItem
                        key={index}
                        disablePadding
                        component="a"
                        href={link}
                        onClick={() => label === "Loans" && navigate("/")}
                      >
                        <ListItemText primary={label} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={6} md={6}>
                <List
                  sx={{
                    "& .MuiListItem-root": {
                      color: "white",
                    },
                    cursor: "pointer",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary="Legal"
                      sx={{
                        marginBottom: "1.5rem",
                        marginLeft: "1rem",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          height: " 1rem",
                          width: "1rem",
                          backgroundColor: "white",
                          borderRadius: "5px 5px 5px 15px",
                          margin: "7px -24px",
                        },
                      }}
                    />
                  </ListItem>

                  {footerColumn2.map(({ label, link }, index) => {
                    return (
                      <ListItem
                        key={index}
                        disablePadding
                        component="a"
                        href={link}
                      >
                        <ListItemText primary={label} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box
          component="p"
          sx={{
            color: "#fff",
            textAlign: "center",
            padding: "1.5rem 0 0 0",
            fontSize: "1rem",
            fontFamily: "poppins",
          }}
        >
          Copyright ©<Box component="span">{new Date().getFullYear()}</Box>{" "}
          Dukka Inc. All Rights Reserved. ™
        </Box>
      </Box>
    </Box>
  );
};
