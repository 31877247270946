import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLoanContext } from "context/LoanContext/LoanContext";
import { useLocation, useNavigate } from "react-router-dom";
import { LoanRequestLayoutStyle } from "./LoanRequestLayoutStyle";

const steps = [
  { label: "Business details", link: "/business-details" },
  { label: "Personal details", link: "/personal-details" },
  { label: "Loan details", link: "/loan-details" },
  // { label: "Bank details", link: "/bank-details" },
  { label: "Guarantor's details", link: "/guarantor's-details" },
];

export const StepCount = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const aboveMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const { completedSteps } = useLoanContext();

  const activeFormStep = () => {
    return steps.findIndex(({ link }) => link === pathname);
  };

  return aboveMediumScreen ? (
    <Box sx={LoanRequestLayoutStyle().StepCountBox1}>
      <Stepper
        nonLinear
        activeStep={activeFormStep()}
        orientation="vertical"
        sx={LoanRequestLayoutStyle().Stepper1}
      >
        {steps.map(({ label, link }, index) => (
          <Step
            key={label}
            completed={completedSteps.includes(link)}
            sx={LoanRequestLayoutStyle().Step1}
          >
            <StepButton
              color="inherit"
              onClick={() =>
                completedSteps.length >= index && navigate(`${link}`)
              }
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  ) : (
    <Box sx={LoanRequestLayoutStyle().StepCountBox2}>
      <Stepper
        nonLinear
        activeStep={activeFormStep()}
        orientation="horizontal"
        sx={LoanRequestLayoutStyle().Stepper2}
      >
        {steps.map(({ label, link }, index) => (
          <Step
            key={label}
            completed={completedSteps.includes(link)}
            sx={LoanRequestLayoutStyle().Step2}
          >
            <StepButton
              color="inherit"
              onClick={() =>
                completedSteps.length >= index && navigate(`${link}`)
              }
            ></StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
