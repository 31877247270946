import { Box, Typography } from "@mui/material";
import React from "react";
import { LandingPageStyle } from "../LandingPageStyle";

export const Steps = ({ text, num }) => {
  return (
    <Box sx={LandingPageStyle().StepBox1}>
      <Box sx={LandingPageStyle().StepBox2}>
        <Typography variant="p" sx={LandingPageStyle().StepTypography1}>
          {num}
        </Typography>
      </Box>
      <Typography
        variant="h4"
        sx={{ fontWeight: "300", fontSize: "1.5rem", marginTop: "1.2rem" }}
      >
        {text}
      </Typography>
    </Box>
  );
};
