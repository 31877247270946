import Input from "../formComponent/InputComponent";
import SelectComponent from "../formComponent/SelectComponent";
import { Telephone } from "../formComponent/Telephone";
import Date from "../formComponent/Date";
import { UploadFileName } from "../formComponent/UploadFileName";
import { UploadID } from "components/LoanRequest/PersonalDetails/UploadID";
import { Checkbox } from "../formComponent/Checkbox";

export const FormField = (props) => {
  const { control, uploadedFile, setupLoadedFile, file, fileTitle, ...rest } =
    props;

  // const [uploadedFile, setupLoadedFile] = useState({
  //   passport: null,
  //   id: null,
  // });

  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "telephone":
      return <Telephone {...rest} />;
    case "select":
      return <SelectComponent {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    case "date":
      return <Date {...rest} />;
    case "file":
      return (
        <UploadID
          fileTitle={fileTitle}
          // file={file}

          {...rest}
        />
      );
    case "signature":
      return (
        <UploadFileName
          fileTitle="Upload a signature. Max size 100kb"
          file={file}
          {...rest}
        />
      );
    default:
      return null;
  }
};
