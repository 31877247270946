import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  
const sexOptions = [
  { label: "select", value: "" },
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
];

const idOptions = [
  { label: "select...", value: "" },
  { label: "International Passport", value: "Passport" },
  { label: "Nigeria's Driver's license", value: "Driver's license" },
  { label: "Nigeria's Voter's card", value: "Voter's card" },
  { label: "NIMC Slip or NIN card", value: "NIN" },
];

const formOneValidationSchema = Yup.object({
  full_name: Yup.string().required("This field is required"),
  date_of_birth: Yup.date().required("This field is required"),
  sex: Yup.string().required("This field is required"),
  home_address: Yup.string().required("This field is required"),
  means_of_identification: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("This field is required"),
  phone_number: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .max(11)
    .required("This field is required"),
  means_of_identification_no: Yup.string()
    .matches(/^[0-9a-z]/gi, "Must be exactly must be a valid id number")
    .required("This field is required"),
  BVN: Yup.string()
    .matches(/^[0-9]{11}$/, "Please enter a valid bvn number")
    .required("This field is required"),
});

const formOneInitialValues = {
  full_name: "",
  date_of_birth: new Date("2005-12-31"),
  sex: "",
  home_address: "",
  means_of_identification: "",
  email: "",
  phone_number: "",
  means_of_identification_no: "",
  applicant_passport: null,
  means_of_identification_image: null,
};

const formTwoInitialValues = {
  applicant_passport: null,
  means_of_identification_image: null,
};

const formTwoValidationSchema = Yup.object({
  applicant_passport: Yup.mixed().nullable().required("This field is required"),

  means_of_identification_image: Yup.mixed()
    .nullable()
    .required("This field is required"),
});

export {
  formOneValidationSchema,
  formOneInitialValues,
  formTwoInitialValues,
  formTwoValidationSchema,
  sexOptions,
  idOptions,
};
