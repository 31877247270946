import ErrorMsg from "./ErrorMsg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from "formik";
import { Box,Typography } from "@mui/material";

const Date = ({ label, name, ...rest }) => {
  return (
    <Box sx={{ fontFamily: "Poppins" }}>
      <label htmlFor={name}><Typography component="span" sx={{color:"red"}}>*</Typography>{label}</label>
      <Field name={name}>
        {({ field, form: { values, setFieldValue } }) => {
          const {value} = field;

          return (
            <DatePicker
              id={name}
              {...rest}
              {...field}
              selected={value}
              type="date"
    
              onChange={(val) => {
            
                setFieldValue(name,val);
              }}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={ErrorMsg} />
    </Box>
  );
};

export default Date;
