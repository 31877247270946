import { createTheme } from "@mui/material";

const mainBlack = "#231F20";
const apricot = "#ec7e81";
export const theme = createTheme({
  palette: {
    primary: {
      main: `${mainBlack}`,
    },
    secondary: {
      main: `${apricot}`,
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 12,
  },
});
