import { useState } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextError } from "../TextError/TextError";
import { FormLabel, Box, Typography } from "@mui/material";
import { ErrorMessage } from "formik";

export const DateInput = (props) => {
  const {
    label,
    name,
    error,
    onChange,
    minDate,
    maxDate,
    disablefuture,
    disablePast,
    required,
    value,
    ...rest
  } = props;
  const [toggleDate, setToggleDate] = useState(false);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box>
          <FormLabel
            htmlFor={name}
            sx={{
              color: "black",
              fontWeight: 500,
            }}
          >
            {label}
            <Typography
              component="span"
              sx={{ color: "red", fontSize: "1rem" }}
            >
              {required}
            </Typography>
          </FormLabel>
          <DesktopDatePicker
            views={["year", "month", "day"]}
            disablefuture={disablefuture}
            disablePast={disablePast}
            maxDate={maxDate}
            onChange={onChange}
            openTo="year"
            inputFormat="DD-MM-YYYY"
            id={name}
            onClose={() => setToggleDate(false)}
            value={value}
            open={toggleDate}
            renderInput={(params) => (
              <TextField
                sx={{ paddingTop: "10px", paddingBottom: "20px" }}
                fullWidth
                label={label}
                onClick={() => {
                  setToggleDate(true);
                }}
                {...params}
                {...rest}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
      <ErrorMessage name={name} component={TextError} />
    </>
  );
};
