import { useState, useContext, createContext } from "react";
import { IntercomProvider } from "react-use-intercom";

const LoanContext = createContext();

export const LoanContextProvider = ({ children }) => {
  const [loanRequest, setLoanRequest] = useState({});
  const [completedSteps, setCompletedSteps] = useState([]);
  const [activeFormStep, setActiveFormStep] = useState(false);



const INTERCOM_APP_ID = "uhqhgsqf";

  return (
    <IntercomProvider
    appId={INTERCOM_APP_ID}
    autoBoot
  >
    <LoanContext.Provider
      value={{
        activeFormStep,
        setActiveFormStep,
        loanRequest,
        setLoanRequest,
        completedSteps,
        setCompletedSteps,
      }}
    >
      {children}
    </LoanContext.Provider>
    </IntercomProvider>
  );
};

export const useLoanContext = () => {
  return useContext(LoanContext);
};
