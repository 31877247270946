import { Box } from '@mui/material'
import NextArrow from "assets/images/arrow.svg"

export const Arrow = () => {
  return (
    <Box>
        <Box component="img" src={NextArrow} alt="arrow" sx={{margin:"3rem 0"}} />
    </Box>
  )
}
