import { Grid, Box } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { FormikControl } from "components/Forms/FormikControl/FormikControl";
import { PersistFormikValues } from "formik-persist-values";
import {
  registerOptions,
  membershipOptions,
  insuranceOptions,
} from "./BusinessDetailsSchema";

export const BusinessDetails = () => {
  const formik = useFormikContext();
  // console.log(formik.values);

  // useEffect(() => {
  //   // Call the callback function whenever form values change
  //   setBusinessValues(formik.values?.businessDetails);
  // }, [formik.values?.businessDetails, setBusinessValues]);

  return (
    <Box>
      <Grid container spacing={{ md: 6 }} sx={{ pb: 3, fontFamily: "Poppins" }}>
        <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
          <Field name="businessDetails.name_of_business">
            {({ field, meta }) => {
              return (
                <FormikControl
                  required="*"
                  control="input"
                  label="Name of business(as registered on the app)"
                  name="businessDetails.name_of_business"
                  type="text"
                  meta={meta}
                  field={field}
                  value={formik.values.businessDetails.name_of_business}
                  onChange={formik.handleChange(
                    "businessDetails.name_of_business"
                  )}
                />
              );
            }}
          </Field>
        </Grid>
        <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
          <Field name="businessDetails.BVN">
            {({ field, meta }) => (
              <FormikControl
                required="*"
                control="input"
                label="BVN"
                name="businessDetails.BVN"
                meta={meta}
                field={field}
                maxLength={11}
                value={formik.values.businessDetails.BVN.replace(
                  /[^0-9]/gi,
                  ""
                )}
                onChange={formik.handleChange("businessDetails.BVN")}
                // onChange={(e) => {
                //   const cleanedValue = e.target.value.replace(/[^0-9]/gi, "");
                //   field.onChange({
                //     target: { name: "BVN", value: cleanedValue },
                //   });
                // }}
                // value={field.value}
                // value={field.BVN.replace(/[^0-9]/gi, "")}
              />
            )}
          </Field>
        </Grid>
      </Grid>

      <Grid container spacing={{ md: 6 }} sx={{ pb: 3 }}>
        <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
          <Field name="businessDetails.account_number">
            {({ field, meta }) => (
              <FormikControl
                disablefuture="true"
                // required="*"
                control="input"
                label="Dukka balance (account number)"
                name="businessDetails.account_number"
                type="text"
                maxLength={10}
                value={formik.values.businessDetails.account_number.replace(
                  /[^0-9]/gi,
                  ""
                )}
                onChange={formik.handleChange("businessDetails.account_number")}
                helperText="Kindly open a dukka balance account."
                meta={meta}
                field={field}
              />
            )}
          </Field>
        </Grid>
        <Grid item md={6} xs={12} sm={12} sx={{ textAlign: "start" }}>
          <Field name="bank">
            {({ field, meta }) => (
              <FormikControl
                // required="*"
                control="input"
                label="Bank"
                name="bank_name"
                disabled
                meta={meta}
                field={field}
                value={(formik.values.businessDetails.bank_name = "Providus")}
          
              />
            )}
          </Field>
        </Grid>
      </Grid>

      {/* radio */}
      <Box>
        <Grid container spacing={{ md: 3 }}>
          <Grid item md={4} xs={12} sm={12}>
            <Field name="businessDetails.is_business_registered">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="radio"
                  label="is your business registered?"
                  name="businessDetails.is_business_registered"
                  options={registerOptions}
                  value={formik.values.businessDetails.is_business_registered}
                  onChange={formik.handleChange(
                    "businessDetails.is_business_registered"
                  )}
                  meta={meta}
                  field={field}
                />
              )}
            </Field>
            <Box
              sx={{
                display: { xs: "initial", md: "none" },
                alignItems: "flex-end",
                // height: "100%",
              }}
            >
              {formik.values.businessDetails.is_business_registered ===
                "Yes" && (
                <Grid item md={6} xs={12} sm={12}>
                  <Field name="BN_CAC_number">
                    {({ field, meta }) => (
                      <FormikControl
                        required="*"
                        control="input"
                        label="If yes, please provide BN/CAC number"
                        name="BN_CAC_number"
                        meta={meta}
                        field={field}
                      />
                    )}
                  </Field>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <Field name="businessDetails.is_business_member_of_union">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="radio"
                  label="Are you a member of any association/union/cooperative?"
                  name="businessDetails.is_business_member_of_union"
                  options={membershipOptions}
                  value={
                    formik.values.businessDetails.is_business_member_of_union
                  }
                  onChange={formik.handleChange(
                    "businessDetails.is_business_member_of_union"
                  )}
                  meta={meta}
                  field={field}
                />
              )}
            </Field>
            <Box
              sx={{
                display: { xs: "initial", md: "none" },
                alignItems: "flex-end",
                // height: "100%",
              }}
            >
              {formik.values.businessDetails.is_business_member_of_union ===
                "Yes" && (
                <Grid item md={6} xs={12} sm={12}>
                  <Field name="businessDetails.name_of_union">
                    {({ field, meta }) => (
                      <FormikControl
                        required="*"
                        control="input"
                        label="If yes,provide the name of association/union/cooperative"
                        name="businessDetails.name_of_union"
                        meta={meta}
                        field={field}
                      />
                    )}
                  </Field>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <Field name="businessDetails.is_business_insured">
              {({ field, meta }) => (
                <FormikControl
                  required="*"
                  control="radio"
                  label="Is your business insured?"
                  name="businessDetails.is_business_insured"
                  options={insuranceOptions}
                  value={formik.values.businessDetails.is_business_insured}
                  onChange={formik.handleChange(
                    "businessDetails.is_business_insured"
                  )}
                  meta={meta}
                  field={field}
                />
              )}
            </Field>
            <Box
              sx={{
                display: { xs: "initial", md: "none" },
                alignItems: "flex-end",
                // height: "100%",
              }}
            >
              {formik.values.businessDetails.is_business_insured === "Yes" && (
                <Grid item md={6} xs={12} sm={12}>
                  <Field name="businessDetails.insurer_name">
                    {({ field, meta }) => (
                      <FormikControl
                        required="*"
                        control="input"
                        label="If yes, provide insurer name"
                        name="businessDetails.insurer_name"
                        meta={meta}
                        field={field}
                      />
                    )}
                  </Field>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={{ md: 2 }} alignItems="flex-end">
          <Grid
            item
            md={4}
            xs={12}
            sm={12}
            sx={{
              display: { xs: "none", md: "initial" },
            }}
          >
            {formik.values.businessDetails.is_business_registered === "Yes" && (
              <Field name="businessDetails.BN_CAC_number">
                {({ field, meta }) => (
                  <FormikControl
                    required="*"
                    control="input"
                    label="If yes, please provide BN/CAC number"
                    name="businessDetails.BN_CAC_number"
                    meta={meta}
                    field={field}
                  />
                )}
              </Field>
            )}
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sm={12}
            sx={{
              display: { xs: "none", md: "initial" },
            }}
          >
            {formik.values.businessDetails.is_business_member_of_union ===
              "Yes" && (
              <Field name="businessDetails.name_of_union">
                {({ field, meta }) => (
                  <FormikControl
                    required="*"
                    control="input"
                    label="If yes,provide the name of association/union/cooperative"
                    name="businessDetails.name_of_union"
                    meta={meta}
                    field={field}
                  />
                )}
              </Field>
            )}
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sm={12}
            sx={{
              display: { xs: "none", md: "initial" },
            }}
          >
            {formik.values.businessDetails.is_business_insured === "Yes" && (
              <Field name="businessDetails.insurer_name">
                {({ field, meta }) => (
                  <FormikControl
                    required="*"
                    control="input"
                    label="If yes, provide insurer name"
                    name="businessDetails.insurer_name"
                    meta={meta}
                    field={field}
                  />
                )}
              </Field>
              // </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <PersistFormikValues
        name="business-details"
        ignoreValues="date_of_birth"
        storage="sessionStorage"
      />
    </Box>
  );
};
