import React, { useEffect } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, ErrorMessage } from "formik";
import { FormField } from "components/LoanGuarantorForm/formComponent/FormField";
import "yup-phone-lite";
import ErrorMsg from "components/LoanGuarantorForm/formComponent/ErrorMsg";
import { createGuarantorRequest } from "service/LoanGuarantor.service";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  initialValues,
  validationSchema,
  bankName,
  employmentOptions,
  currencyConverter,
} from "./Parameters";
import { Style } from "./Style";
import { getApplicantDetails } from "service/LoanApplicant.service";
import { CustomSnackbar } from "components/Snackbar/CustomSnackbar";
import { useSearchParams } from "react-router-dom";

export const LoanGuarantorForm = () => {
  let [searchParams] = useSearchParams();
  const applicant = searchParams.get("applicant");
  const [prefilledData, setPrefilledData] = React.useState("");
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [errorMessage, setErrorMessage] = React.useState("");

  const prefillGuarantorDetails = async (applicant) => {
    try {
      const { data } = await getApplicantDetails(applicant);
      setPrefilledData(data);
    } catch (error) {
      // console.log(error.message);
    }
  };
  useEffect(() => {
    if (!applicant) return;
    prefillGuarantorDetails(applicant);

    //  eslint-disable-next-line
  }, [applicant]);
  const onSubmit = async (values, onSubmitProps) => {
    try {
      // bank name onchange to other
      values.bank_name === "Other" && (values.bank_name = values.otherbank);
      // employment status onchange to other
      values.employment_status === "Other" &&
        (values.employment_status = values.otherstatus);
      // changing the converted currency value of amount requested field to the loan amount needed gotten from the backend directly
      values.amount_reqested_for = Number(
        prefilledData.loan_amount_needed
          .match(/\d/gi)
          .join("")
          .split("")
          .slice(0, -2)
          .join("")
      );

      let data = {
        ...values,
        date: moment(values.date).format("YYYY-MM-DD"),
      };
      const response = await createGuarantorRequest(data);
      onSubmitProps.resetForm();
      navigate("/feedback");
      return response;
    } catch (error) {
      setErrorMessage(
        error.message ?? "something is wrong, please try again later"
      );
      setState({ ...state, open: true });
    }
  };

  return (
    <Box sx={Style.Box}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik, setFieldValue) => {
          // console.log(formik);
          return (
            <Form>
              <Typography variant="h2" sx={Style.TitleTypography}>
                Loan Guarantor Form
              </Typography>
              <Stack>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <Box sx={Style.SubtitleBox}>
                      <Typography variant="h4" sx={Style.SubTitleTypography}>
                        Loan Applicant's Details
                      </Typography>
                      <Typography variant="p" sx={Style.GreyColor}>
                        Provide loan applicant’s details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box>
                      <Grid container columnSpacing="20px">
                        <Grid item xs={12} sm={12} md={12}>
                          <Box
                            component={FormField}
                            control="input"
                            name="full_name_of_applicant"
                            label="Full name"
                            type="text"
                            value={
                              (formik.values.full_name_of_applicant =
                                prefilledData
                                  ? prefilledData.full_name
                                  : formik.values.full_name_of_applicant.replace(
                                      /[^a-z]\s/gi,
                                      ""
                                    ))
                            }
                            sx={Style.InputStyle}
                          />
                        </Grid>
                      </Grid>
                      <Box
                        component={FormField}
                        control="input"
                        name="amount_reqested_for"
                        label="Amount requested for"
                        value={
                          (formik.values.amount_reqested_for = prefilledData
                            ? currencyConverter(
                                Number(prefilledData.loan_amount_needed)
                              )
                            : // currencyConverter(
                              formik.values.amount_reqested_for)
                          // )
                        }
                        onChange={(e) => {
                          formik.handleChange("amount_reqested_for");
                          const toNum = Number(
                            e.target.value.replace(/[^0-9]/g, "")
                          );
                          formik.setFieldValue("amount_reqested_for", toNum);
                        }}
                        sx={Style.amountRequested}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
              <Stack sx={{ marginTop: "6rem" }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{ marginBottom: "2rem", fontFamily: "Poppins" }}
                  >
                    <Box>
                      <Typography variant="h4" sx={Style.SubTitleTypography}>
                        Guarantor's Details
                      </Typography>
                      <Typography variant="p" sx={Style.GreyColor}>
                        Provide your details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{}}>
                    <Box sx={{ marginBottom: "2rem" }}>
                      <Box
                        component={FormField}
                        control="file"
                        name="guarantor_picture"
                        label="Upload Profile Picture"
                        type="file"
                        required="*"
                        fileTitle="Upload a selfie. Max size 200kb"
                        onChange={(file) => {
                          formik.setFieldValue("guarantor_picture", file);
                        }}
                      />
                      <ErrorMessage
                        name="guarantor_picture"
                        component={ErrorMsg}
                      />
                    </Box>
                    {/* means of id */}
                    <Box sx={{ marginBottom: "2rem" }}>
                      <Box
                        component={FormField}
                        control="file"
                        name="means_of_identification_image"
                        label="Upload employment identification card"
                        type="file"
                        required="*"
                        fileTitle="Upload a means of identification. Max size 200kb"
                        onChange={(file) => {
                          formik.setFieldValue(
                            "means_of_identification_image",
                            file
                          );
                        }}
                      />
                      <ErrorMessage
                        name="means_of_identification_image"
                        component={ErrorMsg}
                      />
                    </Box>
                    <Grid container columnSpacing="20px">
                      <Grid item xs={12} sm={12} md={12}>
                        <Box
                          component={FormField}
                          control="input"
                          name="full_name"
                          label="Full name"
                          type="text"
                          required="*"
                          value={formik.values.full_name}
                          onChange={formik.handleChange("full_name")}
                          sx={Style.InputStyle}
                        />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing="20px">
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          component={FormField}
                          control="input"
                          name="email"
                          label="Email"
                          type="email"
                          required="*"
                          value={formik.values.email}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "email",
                              e.target.value.trim(e)
                            )
                          }
                          sx={Style.InputStyle}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{
                          "& .MuiInputBase-input-MuiOutlinedInput-input": {
                            fontSize: "2rem",
                          },
                        }}
                      >
                        <Box
                          component={FormField}
                          control="telephone"
                          name="phone_number"
                          label="Phone number"
                          type="tel"
                          maxLength={11}
                          onChange={formik.handleChange("phone_number")}
                          value={formik.values.phone_number}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      component={FormField}
                      control="input"
                      name="address"
                      label="Address"
                      type="text"
                      required="*"
                      sx={Style.InputStyle}
                    />
                    <Grid container columnSpacing="20px" spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Box
                          component={FormField}
                          control="select"
                          name="bank_name"
                          label="Bank name"
                          options={bankName}
                          value={formik.values.bank_name}
                          onChange={formik.handleChange("bank_name")}
                          sx={{
                            backgroundColor: "rgba(0,0,0,0.04)",
                            border: "none",
                            width: "100%",
                            height: "50px",
                            margin: "1rem 0 0 0",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          component={FormField}
                          control="input"
                          name="account_number"
                          label="Account number"
                          required="*"
                          maxLength={10}
                          value={formik.values.account_number.replace(
                            /[^0-9]/gi,
                            ""
                          )}
                          onChange={formik.handleChange("account_number")}
                          sx={{
                            backgroundColor: "rgba(0,0,0,0.04)",
                            border: "none",
                            width: "100%",
                            height: "50px",
                            margin: "1rem 0 0 0",
                          }}
                        />
                      </Grid>
                    </Grid>
                    {/* Other field for bankName */}
                    {formik.values.bank_name === "Other" && (
                      <Box
                        component={FormField}
                        control="input"
                        name="otherbank"
                        label="Fill in the Guarantor's bank name"
                        type="text"
                        onChange={formik.handleChange("otherbank")}
                        value={formik.values.otherbank}
                        sx={Style.InputStyle}
                      />
                    )}
                    <Grid container columnSpacing="20px" spacing={3}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          component={FormField}
                          control="select"
                          options={employmentOptions}
                          name="employment_status"
                          label="Employment Status"
                          type="text"
                          value={formik.values.employment_status}
                          onChange={formik.handleChange("employment_status")}
                          sx={Style.InputStyle}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          component={FormField}
                          control="input"
                          name="employer"
                          label="Employer"
                          type="text"
                          required="*"
                          sx={Style.InputStyle}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      component={FormField}
                      control="input"
                      name="relationship_with_applicant"
                      label="Relationship with applicant"
                      type="text"
                      required="*"
                      sx={Style.InputStyle}
                    />
                    <Box sx={{ fontFamily: "Poppins" }}>
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: "500",
                          marginBottom: "1rem",
                          display: "block",
                          fontSize: "1rem",
                        }}
                      >
                        <Typography component="span" sx={{ color: "red" }}>
                          *
                        </Typography>
                        Consent
                      </Typography>
                      <Box
                        component={FormField}
                        control="checkbox"
                        name="agreed_to_terms"
                        label="I agree"
                        sx={Style.InputStyle}
                      />
                      <Typography variant="p" sx={Style.ConsentTypography}>
                        I hereby confirm that I stand as a Guarantor in respect
                        of the loan application of the above-named person and
                        stated amount. As a guarantor, I shall be responsible
                        for paying up for the loan amount including interest if
                        he/she fails to pay up as agreed
                      </Typography>
                    </Box>
                    <Grid
                      container
                      columnSpacing="20px"
                      spacing={3}
                      sx={Style.DateSignatureContainer}
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          disabled
                          component={FormField}
                          control="date"
                          name="date"
                          label="Date"
                          value={(formik.values.date = new Date())}
                          onChange={formik.handleChange("date")}
                          sx={Style.InputStyle}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box
                          component={FormField}
                          control="signature"
                          name="signature"
                          label="Signature"
                          type="file"
                          onChange={(file) => {
                            formik.setFieldValue("signature", file);
                          }}
                          sx={Style.InputStyle}
                        />
                        <ErrorMessage name="signature" component={ErrorMsg} />
                      </Grid>
                    </Grid>
                    <Box sx={{ "& .Mui-disabled": { color: "red" } }}>
                      <Button
                        type="submit"
                        disabled={!formik.isValid || formik.isSubmitting}
                        sx={Style.Submit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          );
        }}
      </Formik>
      <CustomSnackbar
        open={open}
        handleClose={() => setState({ ...state, open: false })}
        anchorOrigin={{ vertical, horizontal }}
        errorMessage={errorMessage}
      />
    </Box>
  );
};
