import { Box, Grid, Typography, Container, Paper } from "@mui/material";
import { StepCount } from "./StepCount";
import { LoanRequestLayoutStyle } from "./LoanRequestLayoutStyle";

export const LoanRequestLayout = ({
  children,
  title,
  subtitle,
  backgroundImage,
}) => {
  return (
    <Container
      maxWidth="lg"
      sx={LoanRequestLayoutStyle(backgroundImage).Container}
    >
      <Grid container spacing={{ md: 2 }}>
        <Grid item md={3} xs={12}>
          <StepCount />
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper sx={LoanRequestLayoutStyle().Paper}>
            <Box>
              {/* <Typography as="h4" sx={LoanRequestLayoutStyle().Title}>
                {title}
              </Typography> */}
              <Box sx={LoanRequestLayoutStyle().SubTitle}>{subtitle}</Box>
              <Box sx={{ py: 4, px: 5 }}>{children}</Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        color="primary"
        align="center"
        sx={LoanRequestLayoutStyle().FooterHelp}
      >
        Need help? Email us at
        <Typography
          component="a"
          href="mailto:getaloan@dukka.com"
          sx={LoanRequestLayoutStyle().FooterMail}
        >
          getaloan@dukka.com
        </Typography>
        or call
        <Typography
          component="a"
          color="black"
          href="mailto:getaloan@dukka.com"
          sx={LoanRequestLayoutStyle().FooterCall}
        >
          01-8878000
        </Typography>
      </Typography>
    </Container>
  );
};
