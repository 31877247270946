
import React from 'react';
import { Field, ErrorMessage} from 'formik';
import { Box, Typography } from '@mui/material';
import ErrorMsg from './ErrorMsg';

function InputComponent(props) {
    const {label, name,helperText,required,maxLength, ...rest} = props;

  return (
   
    <Box sx={{marginBottom:"2rem",fontFamily:"Poppins" }}>
    <Typography variant="label" component="label" htmlFor={name} color={name==="otherstatus" || name==="otherbank" ? "red" : "primary" } sx={{fontSize:"1rem"}}> 
    <Typography component="span" sx={{color:"red"}}>{required}</Typography>
    {label}
    </Typography>
    <br/>


                        
      <Field id={name} name={name} maxLength={maxLength} {...rest} style={{border:"none", outline:"none", fontSize:"1.2rem",paddingLeft:"0.5rem"}}  />
      <ErrorMessage name={name} component={ErrorMsg}   />
    </Box>
  );
}


export default InputComponent