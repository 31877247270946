import * as Yup from "yup";

const employmentOptions = [
  { key: "Select...", value: "" },
  { key: "Employed", value: "Employed" },
  // { key: "UnEmployed", value: "UnEmployed" },
  { key: "Self Employed", value: "Self-Employed" },
  { key: "Retiree", value: "Retiree" },
  // { key: "Other", value: "Other" },
];

const bankName = [
  { key: "Select...", value: "" },
  { key: "Access Bank Plc", value: "accessbank" },
  { key: "Citibank Nigeria Limited", value: "citybank" },
  { key: "Ecobank Nigeria Plc", value: "ecobank" },
  { key: "Fidelity Bank Plc", value: "fidelitybank" },
  { key: "FIRST BANK NIGERIA LIMITED", value: "firstbank" },
  { key: "First City Monument Bank Plc", value: "firstmonument" },
  { key: "Globus Bank Limited", value: "globus" },
  { key: "Guaranty Trust Bank Plc", value: "gtb" },
  { key: "Heritage Banking Company Ltd.", value: "hbc" },
  { key: "Keystone Bank Limited", value: "keystone" },
  { key: "Parallex Bank Ltd", value: "parallex" },
  { key: "Polaris Bank Plc", value: "polaris" },
  { key: "Premium Trust Bank", value: "premium" },
  { key: "Providus Bank", value: "providus" },
  { key: "STANBIC IBTC BANK PLC", value: "stanbic" },
  { key: "Standard Chartered Bank Nigeria Ltd", value: "scbn" },
  { key: "Sterling Bank Plc", value: "sterling" },
  { key: "SunTrust Bank Nigeria Limited", value: "suntrust" },
  { key: "Titan Trust Bank Ltd", value: "titans" },
  { key: "Union Bank of Nigeria Plc", value: "union" },
  { key: "United Bank For Africa Plc", value: "uba" },
  { key: "Unity Bank Plc", value: "unity" },
  { key: "Wema Bank Plc", value: "wema" },
  { key: "Zenith Bank Plc", value: "zenith" },
  { key: "Other", value: "Other" },
];

const initialValues = {
  full_name_of_applicant: "",
  amount_reqested_for: "",
  guarantor_picture: null,
  means_of_identification_image: null,
  full_name: "",
  email: "",
  phone_number: "+234",
  address: "",
  bank_name: "",
  account_number: "",
  otherbank: "",
  employment_status: "",
  otherstatus: "",
  employer: "",
  relationship_with_applicant: "",
  agreed_to_terms: false,
  date: "",
  signature: "",
};

const validationSchema = Yup.object({
  full_name_of_applicant: Yup.string().required("This field is required"),
  // amount_reqested_for: Yup.number()
  //   .required("This field is required")
  //   .min(10000, "Please enter an amount between N10,000 - N200,000")
  //   .max(200000, "Please enter an amount between N10,000 - N200,000"),
  guarantor_picture: Yup.mixed().nullable().required("This field is required"),
  means_of_identification_image: Yup.mixed()
    .nullable()
    .required("This field is required"),
  full_name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("This field is required"),
  phone_number: Yup.string().phone("NG").required("This field is required"),
  address: Yup.string().required("This field is required"),
  bank_name: Yup.string().required("This field is required"),
  account_number: Yup.string()
    .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
    .required("This field is required"),
  employment_status: Yup.string().required("This field is required"),
  // employer: Yup.string().required("This field is required"),
  employer: Yup.string().when("employment_status", {
    is: "Employed",
    then: Yup.string().required("This field is required"),
  }),
  relationship_with_applicant: Yup.string().required("This field is required"),
  agreed_to_terms: Yup.boolean().oneOf(
    [true],
    "It is important you give your consent"
  ),
  date: Yup.string().required("This field is required"),
  signature: Yup.mixed().required("This field is required"),
});

const currencyConverter = (amount) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    maximumSignificantDigits: 3,
  }).format(amount);

export {
  initialValues,
  validationSchema,
  bankName,
  employmentOptions,
  currencyConverter,
};
