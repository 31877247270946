import { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  Paper,
  Button,
  Popover,
} from "@mui/material";
import { Link } from "react-router-dom";
import { CustomButton } from "components/Button/Button";
// import {Popover} from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import { createLoanRequest } from "service/LoanApplicant.service";
// import { useNavigate } from "react-router-dom";
// import { useLoanContext } from "context/LoanContext/LoanContext";
import { ErrorModal } from "components/ErrorModal/ErrorModal";
import { useLoanContext } from "context/LoanContext/LoanContext";
import { createLoanRequest } from "service/LoanApplicant.service";
import { useNavigate } from "react-router-dom";

export const TermsandConditions = () => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const { loanRequest, setLoanRequest } = useLoanContext();
  const [state, setState] = useState({
    open: false,
  });
  const { open } = state;
  const [errorMessage, setErrorMessage] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // console.log(loanRequest);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          bgcolor: "#fff",
          padding: { md: "6rem", xs: "2rem" },
          border: "1px solid #E3E3E3",
          boxShadow: "0px 20px 45px rgba(0, 0, 0, 0.05)",
          borderRadius: "10px",
          textAlign: "left",
          marginTop: { md: 16, xs: 6 },
          marginBottom: { md: 16, xs: 6 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: { md: "40px", xs: "24px" },
            lineheight: "53px",
            paddingBottom: "24px",
            textAlign: "center",
          }}
        >
          Terms and Conditions
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "14px",
            lineHeight: "28px",
            pb: 3,
          }}
        >
          This section covers the provision of loan products to You.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "28px",
            pb: 3,
          }}
        >
          1. ELIGIBILITY
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "14px",
            lineHeight: "28px",
            pb: 3,
          }}
        >
          1.1 You may apply for a loan on the Dukka website/App after you have
          accepted these Terms and Conditions and the Privacy Policy. You hereby
          consent to us using information provided to us, from your Equipment or
          other databanks for the purpose of underwriting your loan application.
          {/* show and hide conmtrol */}
          <Button
            variant="body2"
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              textTransform: "capitalize",
              color: "#E97D80",
              pl: 1,
              cursor: "pointer",
            }}
            onClick={() => setShowMore(true)}
          >
            {showMore ? null : "Read More"}
          </Button>
        </Typography>

        {showMore && (
          <Box component="div">
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              2. YOUR LOAN APPLICATION
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              2.1 An application for the loan will be made on the App and upon a
              successful consideration of your loan application, the loan amount
              may be disbursed into your DUKKA balance or bank account balance
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              2.2 If your loan application is unsuccessful, you will be notified
              on the App and the reasons for the refusal may be provided to you
              on the App. You will also be informed if you are eligible to
              reapply for a loan. Please note that your loan application will be
              unsuccessful if you have defaulted on any past Loan.
            </Typography>

            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              2.3 Dukka reserves the right (in its sole and absolute discretion)
              to approve or decline to approve a Loan even if you have
              previously been issued with a Loan on the App. We reserve the
              right to vary the terms of any Loan depending on our assessment of
              the credit profile of each borrower from time to time. Notice of
              any changes to the terms of any existing loan will be communicated
              to you.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              2.4 CONDITIONS FOR DISBURSEMENT OF LOAN:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              a. A completed online application made via the Dukka website or
              app;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              c. A duly filled guarantors form
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              d.Verification of all information provided by you and your
              guarantorA duly filled guarantors form
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              e. Any other relevant document as may be required by Dukka.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              2.5 The details of your loan –principal amount, interest payable,
              repayment days, transaction fees, default etc will be made
              available to you on the website,App/or via any other acceptable
              means of notification; for every successful loan application.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              3. CREDIT REFERENCE BUREAUS
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              3.1 In assessing your loan application, we may request your
              Personal Information, credit profile and credit related
              transactions from Credit Reference Bureaus in order to assess your
              creditworthiness and to assign you a credit score. You confirm
              that we may obtain such information from the bureaus and consent
              to the disclosure and provision of such Personal Information to
              the bureaus.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              3.2 As part of our regulatory obligations, we may share
              information about your loan transactions (including non
              con-compliance with the terms and conditions of this Agreement and
              defaults on any Loan) on the App with Credit Reference Bureaus and
              regulators.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              4. YOUR RIGHTS AND OBLIGATIONS AS A BORROWER
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              4.1 As a borrower, You
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              4.1.1 undertake to repay all amounts including principal, interest
              including (default interest where applicable), fees, taxes, or any
              other amount that may be due to us on the dates agreed using the
              repayment channels made available on the App;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              4.1.2 shall be held responsible for the repayment of any Loan
              disbursed to you on the App;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              4.1.3 ensure that your card, Wallet or DUKKA balances are pre
              funded on or before the repayment dates. You hereby authorise us
              to charge any other card or accounts listed against your name on
              the App after unsuccessful attempts on any of your
              account/cards/balance. You shall also indemnify us for any charges
              or fees due in respect of failed or unsuccessful direct debits on
              your DUKKA balances or cards.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              4.1.4 shall not use the proceeds of the Loan for any illegal or
              criminal activity including but not limited to money laundering or
              financing of terrorism.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              4.1.4 shall not use the proceeds of the Loan for any illegal or
              criminal activity including but not limited to money laundering or
              financing of terrorism.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              4.1.5 provide all necessary assistance that we may require for the
              collection or realisation of any outstanding amounts due from You
              to us.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              5. INTEREST AND FEES
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              5.1 Any interest and where applicable, Transaction Fees, due on
              your Loan shall be displayed on the App. We may from time to time
              amend or vary the interest or Transaction Fees and unless
              otherwise stated, such changes will not be backdated. We will use
              reasonable endeavours to notify You of any changes in relation to
              Transaction Fees within a reasonable period before such changes
              are implemented including displaying notices of the changes on the
              App or on our website.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              5.2 If you fail to make any payments due to Dukka at the due date
              for payment, Dukka may apply default interest or penalties on such
              outstanding amounts at a rate to be communicated to You.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6. DEFAULT ON LOAN
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.1 An event of default (Event of Default) occurs when:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.1.1 You fail to pay any sum payable under a Loan in accordance
              with a scheduled repayment;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.1.2 any representation/information, warranty or assurance made
              or given by You in connection with the application for a Loan or
              any information or documentation supplied by you is incorrect,
              inaccurate or misleading;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.1.3 You do anything which may prejudice Dukka’s rights under
              this Agreement;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.1.4 you use or attempt to use the App or the Services for an
              unauthorised purpose;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.1.5 You are declared bankrupt, insolvent or unable to pay his
              debts as they fall due under the laws of the Federal Republic of
              Nigeria; or
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.1.5 You are declared bankrupt, insolvent or unable to pay his
              debts as they fall due under the laws of the Federal Republic of
              Nigeria; or
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.1.6 you are in breach of any of the terms and conditions of this
              Agreement;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.2 At any time after an Event of Default has occurred, Dukka may,
              without prejudice to any other right or remedy granted to it under
              any law:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.2.1 terminate this Agreement;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.2.2 declare that the Loan (and all accrued interest and all
              other amounts outstanding) under this Agreement is immediately due
              and payable, whereupon they shall become immediately due and
              payable;
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.2.3 enforce its rights to recover the Loan by exercising all
              such rights available to a lender under Nigerian law including but
              not limited to the set off rights in clause 26.4 and 27 below, the
              right to assign the Loan to collection agencies etc; and
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.2.4 supply information concerning the Event of Default to Credit
              Reference Bureaus. A copy of any information concerning you sent
              to a Credit Reference Bureau shall be made available to you upon
              written request.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.2.5 Dukka reserves the right to assign its right, title and
              interest in the Loan and rights under the Agreement to an external
              collections agency who will take all reasonable steps to collect
              the outstanding Loan (together with any interest and fees
              accrued), and in this regard, Dukka shall have the right to share
              with such collection agencies, any information about You
              (including transaction history, Personal Information, relevant
              information) which it deems relevant.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.2.6 Dukka also reserves the right to institute legal proceedings
              for the recovery of the Loan against you without any prior notice.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.2.7 You shall be responsible for all legal costs and expenses
              incurred by Dukka in connection with any enforcement action taken
              by Dukka or the collection agency for the collection and repayment
              of the Loan (together with any interest or fees due on the Loan).
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.3 In the event that an Event of Default occurs, You grant Dukka
              the right to notify recovery or collection agencies who may assist
              with the recovery of the outstanding Loan amount from you and your
              guarantors. You agree and consent to such notification.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              6.4 Where any Loan or any amount is due and outstanding following
              the due date, You authorise us (without notice to you) to set off
              any amounts outstanding in your DUKKA balance, bank account,
              Wallets or Investment Account, including all interests earned to
              repay the outstanding loan amount without any prior notice to you.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              7. SET OFF
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              7.1 By signing this Agreement and by drawing on the Loan, You
              covenant to repay the loan as and when due. In the event that You
              fail to repay the loan as agreed, and the loan becomes delinquent,
              Dukka shall have the right to report the delinquent loan to the
              CBN through the Credit Risk management System (CRMS) or by any
              other means and request the CBN to exercise its regulatory power
              to direct all banks and other financial institutions under its
              regulatory purview to set-off your indebtedness from any money
              standing to your credit in any bank account balance and from any
              other financial assets they may be holding for your benefit.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              7.2 You covenant and warrant that Dukka shall have power to
              set-off your indebtedness from all such monies and funds standing
              to your credit/benefit in any and all such accounts or from any
              other financial assets belonging to you and in the custody of any
              such bank or financial institution.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              7.3 You hereby waive any right of confidentiality whether arising
              under common law or statue or in any other manner whatsoever and
              irrevocably agree that you shall not argue to the contrary before
              any court of law, tribunal, administrative authority or any other
              body acting in any judicial or quasi-judicial capacity.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              8. CONFIDENTIAL INFORMATION
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              The parties acknowledge that in the performance of their duties
              under this Agreement, either party may communicate to the other
              (or its designees) certain confidential and proprietary
              information, including without limitation information concerning
              each party’s services, know-how, technology, techniques, or
              business or marketing plans (collectively, the “Confidential
              Information”) all of which are confidential and proprietary to,
              and trade secrets of, the disclosing party. Confidential
              Information does not include information that: (i) is public
              knowledge at the time of disclosure by the disclosing party; (ii)
              becomes public knowledge or known to the receiving party after
              disclosure by the disclosing party other than by breach of the
              receiving party’s obligations under this section or by breach of a
              third party’s confidentiality obligations; (iii) was known by the
              receiving party prior to disclosure by the disclosing party other
              than by breach of a third party’s confidentiality obligations; or
              (iv) is independently developed by the receiving party.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              As a condition to the receipt of the Confidential Information from
              the disclosing party, the receiving party shall: (i) not disclose
              in any manner, directly or indirectly, to any third party any
              portion of the disclosing party’s Confidential Information; (ii)
              not use the disclosing party’s Confidential Information in any
              fashion except to perform its duties under this Agreement or with
              the disclosing party’s express prior written consent; (iii)
              disclose the disclosing party’s Confidential Information, in whole
              or in part, only to employees and agents who need to have access
              thereto for the receiving party’s internal business purposes; (iv)
              take all necessary steps to ensure that its employees and agents
              are informed of and comply with the confidentiality restrictions
              contained in this Agreement; and (v) take all necessary
              precautions to protect the confidentiality of the Confidential
              Information received hereunder and exercise at least the same
              degree of care in safeguarding the Confidential Information as it
              would with its own confidential information, and in no event shall
              apply less than a reasonable standard of care to prevent
              disclosure.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              9. TERMINATION
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              You may terminate this Agreement by paying back your loan and
              interests.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              you do not comply with any of the provisions of this Agreement; we
              are required to do so by a Law; we are directed by a Card Network
              or issuing financial institution; or where a suspicious or
              fraudulent transaction occurs.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              Restricted Activities & Acceptable Use Policy
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              You are independently responsible for complying with all
              applicable laws related to your use of our website and services.
              However, by accessing or using Dukka, you agree to comply with the
              terms and conditions of our Acceptable Use Policy and are
              restricted from the activities specified in it which you can read
              on our Acceptable Use Policy page.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              10. PRIVACY POLICY
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              Dukka is committed to managing your Personal Information in line
              with global industry best practices. You can read our Privacy
              Policy to understand how we use your information and the steps we
              take to protect your information.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              11. DISCLAIMER
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              WE TRY TO KEEP DUKKA AVAILABLE AT ALL TIMES, BUG-FREE AND SAFE,
              HOWEVER, YOU USE IT AT YOUR OWN RISK.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              OUR WEBSITE AND SERVICES ARE PROVIDED “AS IS” WITHOUT ANY EXPRESS,
              IMPLIED AND/OR STATUTORY WARRANTIES (INCLUDING, BUT NOT LIMITED
              TO, ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND
              NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS). WITHOUT
              LIMITING THE GENERALITY OF THE FOREGOING, Dukka MAKES NO WARRANTY
              THAT OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT
              OUR WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE.
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              THROUGH OUR WEBSITE OR FROM Dukka, ITS PARENTS, SUBSIDIARIES, OR
              OTHER AFFILIATED COMPANIES, OR ITS OR THEIR SUPPLIERS (OR THE
              RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS OF ANY SUCH
              ENTITIES) (COLLECTIVELY, "Dukka PARTIES") SHALL CREATE ANY
              WARRANTY
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              12. LIMITATION OF LIABILITY
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              IN NO EVENT WILL ANY OF THE DUKKA PARTIES BE LIABLE FOR (A) ANY
              INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES
              OR (B) ANY DAMAGES WHATSOEVER IN EXCESS OF THE AMOUNT OF THE
              TRANSACTION OR TWENTY THOUSAND UNITED STATES DOLLARS
              (US$20,000.00), WHICHEVER IS LESSER (INCLUDING, WITHOUT
              LIMITATION, THOSE RESULTING FROM LOSS OF REVENUES, LOST PROFITS,
              LOSS OF GOODWILL, LOSS OF USE, BUSINESS INTERRUPTION, OR OTHER
              INTANGIBLE LOSSES), ARISING OUT OF OR IN CONNECTION WITH Dukka’S
              WEBSITE, PRODUCTS OR SERVICES (INCLUDING, WITHOUT LIMITATION, USE,
              INABILITY TO USE, OR THE RESULTS OF USE OF DUKKA’S WEBSITES OR
              SERVICES), WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, CONTRACT,
              TORT, STATUTE, OR ANY OTHER LEGAL THEORY.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              13. EXCLUSIONS
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              Some jurisdictions do not allow the exclusion of certain
              warranties or the limitation or exclusion of liability for certain
              damages. Accordingly, some of the above disclaimers and
              limitations of liability may not apply to you. To the extent that
              any Dukka Party may not, as a matter of applicable law, disclaim
              any implied warranty or limit its liabilities, the scope and
              duration of such warranty and the extent of the Dukka’s Party's
              liability shall be the minimum permitted under such applicable
              law.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              14. INDEMNITY
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              You agree to defend, indemnify, and hold Dukka, its officers,
              directors, employees, agents, licensors, and suppliers, harmless
              from and against any claims, actions or demands, liabilities and
              settlements including without limitation, reasonable legal and
              accounting fees, resulting from, or alleged to result from, your
              violation of these Agreement.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              15. UPDATES, MODIFICATIONS & AMENDMENTS
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              We may need to update, modify or amend our Terms of Service as our
              technology evolves. We reserve the right to make changes to this
              Terms of Service at any time by giving notice to users on this
              page.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              We advise that you check this page often, referring to the date of
              the last modification on the page. If you have any objection to
              any of the changes to this Terms of Service, you must cease using
              our website and/or services immediately.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              16. APPLICABLE LAWS
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              These Terms of Use shall be interpreted and governed by the laws
              currently in force in the Federal Republic of Nigeria.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              17. LEGAL DISPUTES
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              We shall make an effort to settle all disputes amicably. Any
              dispute arising out of this Agreement which cannot be settled, by
              mutual agreement/negotiation within 1 (one) month shall be
              referred to arbitration by a single arbitrator at the Lagos
              Multi-Door Courthouse (“LMDC”) and governed by the Arbitration and
              Conciliation Act, Cap A10, Laws of the Federal Republic of
              Nigeria. The arbitrator shall be appointed by both of us (we and
              you), where both of us are unable to agree on the choice of an
              arbitrator, the choice of arbitration shall be referred to the
              LMDC. The findings of the arbitrator and subsequent award shall be
              binding on both of us. Each of us shall bear our respective costs
              in connection with the Arbitration. Venue for the arbitration
              shall be Lagos, Nigeria.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              18. SEVERABILITY
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              If any portion of these Terms of Use is held by any court or
              tribunal to be invalid or unenforceable, either in whole or in
              part, then that part shall be severed from these Terms of Use and
              shall not affect the validity or enforceability of any other part
              in this Terms of Use.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              19. MISCELLANEOUS
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "14px",
                lineHeight: "28px",
                pb: 3,
              }}
            >
              You agree that all agreements, notices, disclosures and other
              communications that we provide to you electronically satisfy any
              legal requirement that such communications be in writing.
              Assigning or sub-contracting any of your rights or obligations
              under these Terms of Use to any third party is prohibited. We
              reserve the right to transfer, assign or sub-contract the benefit
              of the whole or part of any rights or obligations under these
              Terms of Use to any third party.
              <Button
                variant="body2"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  color: "#E97D80",
                  pl: 1,
                  cursor: "pointer",
                }}
                onClick={() => setShowMore(false)}
              >
                show less
              </Button>
            </Typography>
          </Box>
        )}

        <Grid container spacing={{ md: 6, xs: 2 }}>
          <Grid item xs={6} md={6}>
            <CustomButton
              text="DECLINE"
              component={Link}
              to="/"
              color="primary"
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <CustomButton
              text="ACCEPT"
              sx={{ width: "100%", color: "white" }}
              onClick={handleClick}
            />
            <Box>
              <Popover
                id={id}
                open={openPopOver}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box
                  sx={{
                    width: "300px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ p: 2 }}>Link bank account ?</Typography>
                  <Box sx={{ display: "flex" }}>
                    <CustomButton
                      text="YES"
                      component={Link}
                      to="/link-bank-account"
                      aria-describedby={id}
                      variant="text"
                      disabled={true}
                      onClick={handleClick}
                      sx={{ marginRight: "1rem" }}
                    />
                    <CustomButton
                      text="No"
                      aria-describedby={id}
                      variant="text"
                      onClick={async (event, values) => {
                        event.preventDefault();
                        try {
                          await createLoanRequest({
                            ...loanRequest,
                            ...values,
                          });
                          navigate("/feedback");
                          sessionStorage.clear();
                        } catch (error) {
                          setErrorMessage(error);
                          setState({ ...state, open: true });
                        }
                      }}
                      sx={{ marginRight: "1rem" }}
                    />
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <ErrorModal
        openModal={open}
        handleClose={() => setState({ ...state, open: false })}
        errorMessage={errorMessage}
      />
    </Container>
  );
};
