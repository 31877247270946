import { Button, Box, Typography, FormLabel } from "@mui/material";
import { useState } from "react";
import uploadIcon from "assets/images/file-upload.png";

export const PassportUpload = ({
  fileTitle,
  onChange,
  label,
  name,
  required,
}) => {
  const [file, setFile] = useState({
    raw: null,
    displayImage: null,
  });
  const [error, setError] = useState(null);

  const resetState = () => {
    // reset state in child
    setFile({
      raw: null,
      displayImage: null,
    });

    setError(null);

    // let parent know to reset state also
    onChange(null);
  };

  const onFileUpload = ({ target }) => {
    resetState();
    const file = target.files?.[0];

    if (!file) {
      setError("Invalid file");
      return;
    }

    const filesize = (file.size / 1024 / 1024).toFixed(4);
    const fileisToolarge = filesize > 0.2;

    if (fileisToolarge) {
      setError("File too large");
      return;
    }

    setFile({
      raw: file,
      displayImage: URL.createObjectURL(file),
    });
    onChange(file);
  };

  return (
    <>
      <FormLabel
        variant="label"
        component="label"
        htmlFor={name}
        sx={{
          color: "black",
          fontWeight: 500,
          paddingBottom: "10px",
        }}
      >
        {label}
        <Typography component="span" sx={{ color: "red", fontSize: "1rem" }}>
          {required}
        </Typography>
      </FormLabel>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          border: "1px solid #e3e3e3",
          width: "100%",
          height: "53px",
          marginTop: "0.7rem",
          borderRadius: "5px",
        }}
      >
        <Box
          color={error ? "red" : "#8E8E93"}
          sx={{
            fontSize: { xs: "0.5rem", sm: "1rem" },
            overflow: "hidden",
            width: "50%",
            paddingLeft: { xs: "0.5rem", sm: "0" },
            textAlign: "center",
          }}
        >
          {error ?? file.raw?.name ?? fileTitle}
        </Box>

        <Button component="label">
          <Box component="img" src={uploadIcon} alt="upload" />
          <input
            hidden
            accept="image/png, image/jpeg, application/pdf"
            type="file"
            onChange={onFileUpload}
          />
        </Button>
      </Box>
    </>
  );
};
