import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const employmentOptions = [
  { label: "select...", value: "" },
  { label: "Employed", value: "Employed" },
  { label: "Self Employed", value: "Self-Employed" },
  { label: "Retiree", value: "Retiree" },
];
const initialValues = {
  guarantor_full_name: "",
  guarantor_employment_status: "",
  guarantor_passport: "",
  guarantor_employer: "",
  guarantor_phone_number: "",
  guarantor_address: "",
  guarantor_email_address: "",
  other_employment_Status: "",
  referral_code: "",
};

const validationSchema = Yup.object({
  guarantor_full_name: Yup.string().required("This field is required"),
  guarantor_passport: Yup.string().required("This field is required"),
  guarantor_employment_status: Yup.string().required("This field is required"),
  guarantor_employer: Yup.string().when("guarantor_employment_status", {
    is: "Employed",
    then: Yup.string().required("This field is required"),
  }),
  guarantor_phone_number: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("This field is required"),
  guarantor_address: Yup.string().required("This field is required"),
  guarantor_email_address: Yup.string()
    .email("Invalid email format")
    .required("This field is required"),
});

export { initialValues, validationSchema, employmentOptions };
