import * as React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import { TextError } from "../TextError/TextError";
import { ErrorMessage } from "formik";

export const RadioButtonGroup = (props) => {
  const { label, name, value, options, onChange, error, required, ...rest } =
    props;

  return (
    <>
      <FormControl>
        <FormLabel id={name} sx={{ color: "black", fontWeight: 500 }}>
          {label}
          <Typography component="span" sx={{ color: "red", fontSize: "1rem" }}>
            {required}
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby={name}
          id={name}
          onChange={onChange}
          value={value || ""}
          {...rest}
          sx={{
            "& .css-25cpdl-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
              color: "#8E8E93",
            },
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              key={option.value.toString()}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <ErrorMessage name={name} component={TextError} />
    </>
  );
};
