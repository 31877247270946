import defaultImage from "assets/images/transparentBg.png";

export const LoanRequestLayoutStyle = (backgroundImage) => {
  return {
    Container: {
      paddingBottom: { md: 6, xs: 5 },
      paddingTop: { md: 12, xs: 12 },
      position: "relative",
      backgroundImage: { xs: `url(${backgroundImage ?? defaultImage})` },
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left bottom",
      width: "100%",
    },
    Paper: {
      bgcolor: "transparent",
      padding: { md: "2rem", xs: 0 },
      border: "1px solid #E3E3E3",
      boxShadow: "0px 20px 45px rgba(0, 0, 0, 0.05)",
      borderRadius: "10px",
      margin: {
        xs: "0 auto",
      },
    },
    Title: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: { md: "40px", xs: "30px" },
      lineheight: "53px",
      paddingBottom: "12px",
      textAlign: "center",
      paddingTop: { xs: "2rem" },
    },
    SubTitle: {
      color: "#979797",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: { md: "14px", xs: "13px" },
      lineHeight: "28px",
      textAlign: "center",
    },
    FooterHelp: {
      marginTop: 8,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "40px",
    },
    FooterMail: {
      fontWeight: "bold",
      color: "black",
      textDecoration: "none",
      ml: 1,
      mr: 1,
    },
    FooterCall: {
      fontWeight: "bold",
      textDecoration: "none",
      ml: 1,
    },
    StepCountBox1: {
      maxWidth: 400,
      mt: 14,
      "& .MuiStepIcon-text": {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "20px",
      },
    },
    Stepper1: {
      "& .MuiStepConnector-line": {
        width: "27.28px",
        height: "0px",
        borderLeft: "1px dashed #8E8E93",
      },
    },
    Step1: {
      "& .MuiStepLabel-label": {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "15px",
        color: "#8E8E93",
      },
      "& .MuiStepLabel-label.Mui-active": {
        fontWeight: 500,
        color: "black",
      },
      "& .MuiSvgIcon-root": {
        fill: "#00000014",
        borderRadius: "50%",
        width: "2rem",
        height: "2rem",
      },
      "& .MuiStepIcon-text": {
        fill: "gray",
        fontSize: "14px",
      },
      "& .MuiStepIcon-root.Mui-active": {
        border: "1px solid #E97D80",
      },
      "& .MuiStepIcon-root.Mui-completed": {
        fill: "#E97D80",
      },
    },
    StepCountBox2: {
      maxWidth: 350,
      mx: "auto",
      paddingBottom: 3,
      "& .MuiStepIcon-text": {
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "20px",
      },
    },
    Stepper2: {
      "& .MuiStepConnector-line": {
        width: "15px",
        height: "0px",
        borderLeft: "1px dashed #8E8E93",
      },
    },
    Step2: {
      "& .MuiStepLabel-label": {
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "15px",
        color: "#8E8E93",
      },

      "& .MuiStepIcon-root": {
        fill: "#00000014",
        borderRadius: "50%",
        width: "2rem",
        height: "2rem",
      },

      "& .MuiStepIcon-text": {
        fill: "gray",
        fontSize: "10px",
      },
      "& .MuiStepIcon-root.Mui-active": {
        border: "1px solid #E97D80",
      },
      "& .MuiStepIcon-root.Mui-completed": {
        fill: "#E97D80",
      },
    },
  };
};
