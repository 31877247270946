import * as React from "react";
import { Snackbar, Alert } from "@mui/material/";

export const CustomSnackbar = ({
  errorMessage,
  anchorOrigin,
  open,
  handleClose,
  key,
  severity = "error",
}) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={handleClose}
      key={key}
      sx={{ mt: 18 }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%", fontSize:"20px" }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};