import {
  MenuItem,
  Select as MUISelect,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import { TextError } from "../TextError/TextError";
import { ErrorMessage } from "formik";

export const Select = (props) => {
  const { name, label, options, value, onChange, error, required, ...rest } =
    props;

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          color: "black",
          fontWeight: 500,
          paddingBottom: "10px",
        }}
      >
        {label}
        <Typography component="span" sx={{ color: "red", fontSize: "1rem" }}>
          {required}
        </Typography>
      </FormLabel>
      <MUISelect
        labelId={name}
        id={name}
        defaultValue=""
        value={value}
        onChange={onChange}
        {...rest}
      >
        {options.map((option) => {
          return (
            <MenuItem
              label={option.label}
              value={option.value}
              disabled={option.value === "" ? true : false}
              key={option.value}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </MUISelect>
      <ErrorMessage name={name} component={TextError} />
    </FormControl>
  );
};
