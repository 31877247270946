import { Field, ErrorMessage,} from "formik";
import { Box, Typography } from "@mui/material";
import ErrorMsg from './ErrorMsg';



// const OtherField = ({label, name, ...rest})=>{
//   return (
//       <Box sx={{marginTop:"1rem"}}>
//        <Typography variant="label" component="label" htmlFor={name} sx={{color:"red", }} >{name === "bankname" ?"Fill in the guarantors's bank name" : name === "employment"?"Guarantors employer" : null}</Typography>
// <br/>

// <Field id={name} name={name} {...rest} style={{border:"none", outline:"none", fontSize:"1rem",paddingLeft:"0.5rem", }}  />
//       </Box>
    
//   )
// }

const SelectComponent = ({ label, name, options,value, ...rest }) => {
  


  return (
    <Box>
      <Typography component="label" htmlFor={name} sx={{fontSize:"1rem"}}>
      <Typography component="span" sx={{color:"red"}}>*</Typography>
        {label}
      </Typography>
      <Field
        as="select"
        name={name}
        id={name}
        {...rest}
    
        style={{ outline:"none", fontSize:"1.2rem",paddingLeft:"0.5rem" }}
       
      >
        {options.map((option) => {
          return (
            <Typography
            
              variant="option"
              component="option"
              key={option.value}
              value={option.value}
              sx={{ background: "#A6A2A2", fontSize:"1.2rem" }}
              disabled={option.value === '' ? true : false}
            >
              {" "}
              {option.key}{" "}
            </Typography>
          );
        })}
      </Field>

      <ErrorMessage name={name} component={ErrorMsg}   />


    </Box>
  );
};

export default SelectComponent;
